import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {
  StyledBackIcon,
  StyledDownloadWrapper,
  StyledIconWrapper,
  StyledLoaderWrapper,
  StyledTextWrapper,
  StyledTitleWrapper,
  StyledHeaderWrapper,
  StyledDownloadBtn,
  StyledDownloadIcon,
  StyledNoRecordWrapper,
  StyledTableCell,
} from "./CurrentLeads.style";
import ArrowBack from "../../assets/icon/arrow-back.png";
// import CloseIcon from "../../assets/icon/close.png";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CSVLink } from "react-csv";
import DownloadIcon from "../../assets/icon/download.png";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const HeaderRow = [
  "Title",
  "Lead URL",
  "Website",
  "Category",

  "City",
  "Company",
  "Lead Date",
  "Platform",
  "Posted Date",
  "Region",
  "Job Fetch Time",
];

// const typesArray = [
//   "id",
//   "title",
//   "category",
//   "city",
//   "company",
//   "lead_date",
//   "platform",
//   "lead_url",
//   "company_url",
//   "posted_date",
//   "region",
//   "job_fetch_timestamp",
// ];

export default function ValidLeadsDetails(props: any) {
  const { currentLeads, currentLeadsLoader } = useSelector(
    (state: any) => state.leadsStats
  );

  return (
    <div>

              <TableContainer sx={{ maxHeight: "500px", marginTop: "16px" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        fontWeight: "bolder",
                        border: "none",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          minWidth: "100px",
                          border: "none",
                        }}
                      >
                        Id
                      </TableCell>
                      {HeaderRow.map((heading: string, index: number) => (
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            minWidth: "200px",
                            border: "none",
                          }}
                          align={index > 4 ? "left" : "left"}
                          key={index}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.validLeads?.map((item: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          border: 0,

                          "&:td, &:th": {
                            border: 0,
                            fontSize: "12px",
                          },
                          fontSize: "12px",
                          "*": {
                            borderBottom: 0,
                            fontSize: "12px",
                            border: 0,
                          },

                          background: index % 2 === 0 ? "#FFFFF;" : "#F8F8F8",
                          padding: "8px",

                          borderRadius: "8px",
                        }}
                      >
                        <StyledTableCell>{item.id}</StyledTableCell>

                        {/* {typesArray.map((type) => (
                          <>
                            <StyledTableCell>{item[type]}</StyledTableCell>
                          </>
                        ))} */}

                        <StyledTableCell align="left">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">Title</Typography>
                                <Typography component="p">
                                  {item.job_title}
                                </Typography>
                                <a target={"_blank"} href={item.leadurl}>
                                  <Typography
                                    noWrap
                                    sx={{ width: "200px", fontSize: "12px" }}
                                  >
                                    {item.leadurl ? item.leadurl : "-"}
                                  </Typography>
                                </a>
                              </React.Fragment>
                            }
                          >
                            {/* <Button>HTML</Button> */}
                            <Typography
                              noWrap
                              sx={{ width: "250px", fontSize: "12px" }}
                            >
                              {/* {props?.data?.currentLabel=='Valid Leads'?(item.job_title?item.job_title:""):(item.title ? item.title : "-")} */}
                               {item.job_title ? item.job_title : "-"}
                            </Typography>
                          </HtmlTooltip>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <a target={"_blank"} href={item.leadurl}>
                            <Typography
                              noWrap
                              sx={{ width: "300px", fontSize: "12px" }}
                            >
                              {/* {props?.data?.currentLabel === 'Valid Leads'?(item.leadurl?item.leadurl:"-"):(item.lead_url ? item.lead_url : "-")} */}
                              {item.leadurl ? item.leadurl : "-"}
                            </Typography>
                          </a>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <a target={"_blank"} href={item.website}>
                            <Typography
                              noWrap
                              sx={{ width: "200px", fontSize: "12px" }}
                            >
                              {item.website ? item.website : "-"}
                            </Typography>
                          </a>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.job_category ? item.job_category : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.city ? item.city : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.company ? item.company : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.date_identified ? item.date_identified : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.plateform2 ? item.plateform2 : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.date_added ? item.date_added : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.region ? item.region : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.date_identified
                            ? item.date_identified
                            : "-"}
                        </StyledTableCell>

                        {/* <TableCell align="center">{item.validated}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
         
    </div>
  );
}
