// Copyright 2019-2022 @polkadot/extension-koni-ui authors & contributor
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import Box from "@mui/material/Box";

interface Props {
  children: React.ReactNode;
  className?: string;
  error?: Error | null;
  trigger?: string;
}

interface State {
  error: Error | null;
}

// NOTE: This is the only way to do an error boundary, via extend
class ErrorBoundary extends React.Component<Props> {
  public override state: State = { error: null };

  public static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  public override componentDidUpdate(prevProps: Props) {
    const { error } = this.state;
    const { trigger } = this.props;

    if (error !== null && prevProps.trigger !== trigger) {
      this.setState({ error: null });
    }
  }

  goHome = () => {
    this.setState({ error: null });
    window.location.replace("/Dashboard");
  };

  public override render(): React.ReactNode {
    const { children } = this.props;
    const { error } = this.state;

    return error ? (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p>An error occured</p>
          <div>
            {`Something went wrong with the query and rendering of this component. ${error.message}`}
          </div>
          <button onClick={this.goHome}>Back to home</button>
        </Box>
      </>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
