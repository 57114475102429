import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Backdrop from "@mui/material/Backdrop";
import InputLabel from "@mui/material/InputLabel";
// import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  min-width: 95%;
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;

export const StyledLabel = styled.p`
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
  margin-right: 20px;
`;
export const StyledDBIconWrapper = styled.img`
  width: 24px;
  height: 24px;
  margin: 9px 0px 8px 0px;
`;

export const StyledDBLabelWrapper = styled.div`
  display: flex;
`;

export const StyledLoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50% !important;
  z-index: 1;
  padding-top: 10%;
`;

export const StyledBackdrop = styled(Backdrop)`
  position: "absolute";
  height: 540px;
  width: 1297px;
  top: 10%;
  left: 10%;
`;

export const StyledLabelScrapper = styled.p`
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 2px;
  margin-right: 8px;
  text-transform: capitalize;
`;
export const StyledSeparator = styled.div`
  color: #333333;
  font-size: 24px;
  margin-top: 4px;
  margin-right: 4px;
`;

export const StyledSelectWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  width: 200px;
  height: 40px;
`;

export const StyledOption = styled.option``;

export const StyledValueLable = styled.div`
  color: #FFFFF !important;
  // font-size: 1px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.3px;
  background: rgba(4, 62, 116, 0.8);
border-radius: 6px 0px 0px 6px;
padding:auto !important;
`;


export const StyledValueLableWrapper = styled.div`
  display:flex;
  justify-content:center;
 
`;
export const StyledValueLableText = styled.b`
  color: #FFFFF !important;
  // font-size: 1px;
  line-height: 27px;
  /* identical to box height */
  padding:10px !important;

 
`;


export const StyledCardWrapper = styled.div`
 
`;


export const StyledDropDownIcon = styled.img`
heigh:20px;
width:20px;
 
`;