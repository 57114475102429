import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const StyledLogoHeading = styled.p`
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.266667px;
  margin: auto !important;
  padding-left: 8px;

  color: #ffffff;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
`;

export const StyledSidebarlogo = styled.img`
  max-height: 40px;
  max-width: 40px;
  fill: red !important;
`;
export const StyledIcon = styled.img`
  max-height: 16px;
  max-width: 16px;
`;

export const StyledIconLeft = styled(ChevronLeftIcon)`
  color: #dde2ff !important;
`;

export const StyledIconRight = styled(ChevronRightIcon)`
  color: #dde2ff !important;
`;
