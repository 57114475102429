import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  width: 100%;
  max-width: 520px;
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;

export const StyledFormLabel = styled(FormControlLabel)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 21px;
  /* identical to box height */

  color: #333333;
`;

export const StyledMUISelect = styled(Select)`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;

export const StyledButton = styled.button`
  background-color: #0057a9;
  border-radius: 6px;
  color: #ffff;
  width: 148px;
  height: 40px;
  left: 1359px;
  top: 449px;
  border: none;
`;

export const StyledSelect = styled.select`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;

export const StyledOption = styled.option`
  background-color: white;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 60px;
`;


export const StyledTitleWrapper = styled.div`
height: 42px;
background: #F8FBFF;
border-radius: 8px;
display:flex;
flex-direction:column;
justify-content:center;
margin-bottom:16px;
margin-top:8px;
padding-left:8px

}
`;
export const StyledTitleText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.3px;

  /* grayscale / black */

  color: #252733;
`;

export const StyledMainWrapper = styled.div`
  padding: 30px;
  color: "#333333";
`;

export const StyledConfigWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const StyledTxtFieldWrapper = styled.div`
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
`;
export const StyledPasswordWrapper = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledLabel = styled.p`
  font-size: 12px;
  color: black;
`;
export const StyledPswdInnerWrapper = styled.p`
  display: flex;
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)`
  margin-left: -30px;
  margin-top: 10px;
  color: gray;
`;
export const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  margin-left: -30px;
  margin-top: 10px;
  color: gray;
`;

export const StyledDebounceKyesWrapper = styled.div`
  width: 100%;
`;



export const StyledBtnWrapper = styled.div`
display:flex;
justicy-centent:space-around;
margin-top:40px;
padding-right:20px
`;

export const StyledContainer = styled.div`
width:50%;
`;
export const StyledInnerBtnWrapper = styled.div`
width:50%;
display:flex;
justify-content:center
`;
