// Copyright 2019-2022 @polkadot/extension-koni-ui authors & contributors
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  className?: string;
}

const Main = ({ children, className }: Props): React.ReactElement<Props> => {
  return <main className={className}>{children}</main>;
};

export default styled(Main)`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #fffff;
  color: #ffffff;
  overflow: auto;
`;
