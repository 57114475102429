import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const instance = axios.create({
  withCredentials: true
})

export const getAccountKey = createAsyncThunk(
  "login/getAccountKey",
  async (key: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}accounts/google/`, { access_token: key }
    );
    localStorage.setItem("token", response?.data?.key);
    localStorage.setItem("userObj", JSON.stringify(response?.data?.data));
    return response.data.data;
  }
);
export const loginWithEmailPassword = createAsyncThunk(
  "login/loginWithEmailPassword",
  async ({ username, email, password }: { username: string; email: string; password: string }, { dispatch }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}accounts/login/`, // Replace with your actual endpoint
      { username, email, password }
    );
    localStorage.setItem("token", response?.data?.key);
    localStorage.setItem("userObj", JSON.stringify(response?.data?.data));
    dispatch(loggedIn(response.data))
    // return response.data.data;
  }
);

const user = JSON.parse(localStorage.getItem("userObj") || '{}');

const token  = localStorage.getItem("token") || null
const initialState = {
  user: user,
  token: token,
  loginResponse: false,
  updateUserResponse: false,
  success: false,
  err: false,
  redirectUrl: '',
  keyGenerated: false,
  loggedInUser: {}

};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loggedIn: (state, { payload }) => {
      state.user = payload.data;
      state.token = payload.token
    },
    loggedOut: (state) => {
      state.success = false;
      state.user = {};
      state.token = null;
      // Reset other relevant state properties if needed
    },
  },
  extraReducers: {
    [getAccountKey.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [getAccountKey.fulfilled.type]: (state: any, action: any) => ({
      ...state,

      keyGenerated: true,
      loggedInUser: action.payload
    }),
    [getAccountKey.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload,

    }),
    [loginWithEmailPassword.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [loginWithEmailPassword.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      success: true,
      loggedInUser: action.payload
    }),
    [loginWithEmailPassword.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
  }),
  }
});

export const { loggedIn, loggedOut } = loginSlice.actions;

export default loginSlice.reducer;
