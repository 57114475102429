import React, { useState, useCallback, ChangeEvent } from "react";
import debounce from 'lodash/debounce';
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";

import {
  StyledHeading,
  StyledSearchInput,
  StyledSearchWrapper,
  StyledPaginationWrapper,
} from "./Configurations.style";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Pagination from "@mui/material/Pagination";
import {
  getScrappersConfig,
  updateConfigStatus,
  getCommonKeywords
} from "../../stores/slices/configurations-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import SearchKeywords from "../../components/SearchKeywords";
import CommonKeywords from "../../components/CommonKeywords";

import ScrapperContols from "../../components/ScrapperControls";
import InputBase from "@mui/material/InputBase";
import TableSortLabel from "@mui/material/TableSortLabel";

import CircularProgress from "@mui/material/CircularProgress";
import { customAxios } from "../../Services/auth-header";

import { Box } from "@mui/material";
import { set } from "lodash";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#00000" : "#39393D",
    opacity: 1,

    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));
const SystemConfigurations = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [switchState, setSwitchState] = useState({}) as any;
  const dispatch = useDispatch<AppDispatch>() as any;

  const handleChange = (
    index: any,
    event: any,
    checked: boolean,
    configObj: any
  ) => {
    const list = Object.assign({}, switchState) as any;

    list["switch-" + index] = checked;

    setSwitchState({
      switchState: list,
    });

    config.results.map((record: any) => {
      if (record.id === index) {
        let obj = {
          id: index,
          status: !configObj.status,
          name: configObj.name,
          path: configObj.path,
        };
        dispatch(updateConfigStatus(obj));
      }
    });
  };
  const { config, commonKeywords } = useSelector((state: any) => state.config);

  // Debounced search function
  const debouncedSearch = useCallback(debounce((query: string) => {
    setCurrentPage(1);  // Reset to the first page on new search
    dispatch(getScrappersConfig({ page: 1, query }));
  }, 500), [dispatch]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handlePagination = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page); // Update the current page state
    dispatch(getScrappersConfig({ page, query: searchTerm }));
  };

  const getCount = () => {
    let count = Math.ceil(config?.count / 10);
    if (config?.count) {
      return count;
    } else {
      return 10;
    }
  };
  React.useEffect(() => {
    dispatch(getCommonKeywords({ page: 1 }));
    dispatch(getScrappersConfig({ page: 1, query: searchTerm }));
  }, [dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        color: "black",
        px: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <StyledHeading>System Configurations</StyledHeading>
        <CommonKeywords commonData={commonKeywords} />
      </Box>

      <StyledSearchWrapper>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "end", alignItems: "center", gap: "20px", marginBottom: "20px" }}>
          <StyledSearchInput
            placeholder="Search by scrapper name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          </Box>
      </StyledSearchWrapper>

      <Box sx={{ width: "100%", display: "flex" }}>
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid #e5e5e5",
            padding: "20px",
            boxShadow: "none",
          }}
        >
          <Table
            sx={{
              "*": {
                border: 0,
              },
              width: "100%",
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow sx={{ border: "none" }}>
                <TableCell sx={{ border: "none", fontWeight: "bold" }}>
                  <TableSortLabel>{"ID"}</TableSortLabel>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ border: "none", fontWeight: "bold" }}
                >
                  <TableSortLabel direction={"desc"}>{"Name"}</TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "none", fontWeight: "bold" }}
                >
                  Status
                </TableCell>

                <TableCell
                  align="center"
                  sx={{ border: "none", fontWeight: "bold" }}
                >
                  Keywords
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: "none", fontWeight: "bold" }}
                >
                  Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {config?.results?.map((config: any, index: number) => (
                <TableRow
                  key={config.name}
                  sx={{
                    "*": {
                      borderBottom: 0,
                    },
                    background: index % 2 === 0 ? "#F5F5F5;" : "#FBFBFB",
                    padding: "8px",
                    border: "none",
                  }}
                >
                  <TableCell component="th" scope="row" sx={{ border: 0 }}>
                    {config.id}
                  </TableCell>
                  <TableCell align="left" sx={{ border: 0 }}>
                    {config.name}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 0 }}>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 0, mr: 1 }}
                          key={index}
                          checked={config.status}
                          onChange={(event: any, checked: any) =>
                            handleChange(config.id, event, checked, config)
                          }
                        />
                      }
                      label={+config?.status ? " On" : " Off"}
                    />
                  </TableCell>
                  <TableCell align="center" sx={{ border: 0 }}>
                    <SearchKeywords key={config.id} scrapperData={config} commonData={commonKeywords} />
                  </TableCell>
                  <TableCell align="center" sx={{ border: 0 }}>
                    <ScrapperContols
                      key={config.id + 1}
                      scrapperData={config}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!config?.results && (
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "20px",
              }}
            >
              <CircularProgress size={80} />
            </Box>
          )}
          <StyledPaginationWrapper>
            <Pagination
              count={getCount()}  // Ensure getCount() is updated to consider total count divided by rows per page
              page={currentPage}  // Controlled component behavior
              variant="outlined"
              shape="rounded"
              size="small"
              sx={{ mt: "8px" }}
              onChange={handlePagination}
            />

            {/* <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={10}
              // onChange={handleRowsChange}
              input={<BootstrapInput />}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select> */}
          </StyledPaginationWrapper>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SystemConfigurations;
