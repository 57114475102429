// Copyright 2019-2022 @polkadot/extension-koni-ui authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { configureStore } from "@reduxjs/toolkit";

import SideBarReducer from "./slices/SideBar";

import LoginReducer from "./slices/login-slice";

import leadsReducer from "./slices/leads-slice";
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import configReducer from './slices/configurations-slice'
import scrappersHealthReducer from './slices/scrappers-health-slice'
import preferences from './slices/preferences-slice'
import emails from './slices/email-stats-slice'
import emailScanner from "./slices/email-scanner-slice";

const reducers = {
  SideBarSelectItem: SideBarReducer,
  LoginReducer: LoginReducer,
  leadsStats: leadsReducer,
  scrappersHealth:scrappersHealthReducer,
  config:configReducer,
  preferences:preferences,
  emailStats:emails,
  emailScanner:emailScanner
};

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: reducers,
  middleware:[thunk]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
