import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

export const StyledMainWrapper = styled.div`
  background: #f6f8f9;
  color: #2c2c2c;
  padding: 15px;
  height: 100%;
`;

export const StyledStepperWrapper = styled.div`
  font-size: 20px;
  background: #ffffff;
  color: #252733;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
`;
export const StyledDividerWrapper = styled.div`
  border: 1px solid #dfe0eb;
  margin-bottom: 10px;
`;
export const StyledSecondWrapper = styled.div`
  font-size: 20px;
  background: #ffffff;
  color: #252733;
  border-radius: 10px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  margin-top: 16px;
`;
export const StyledFormWrapper = styled.div`
  padding: 0px 10px 10px;
`;
export const StyledHeadingText = styled.p`
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #252733;
`;

export const StyledPara = styled.p`
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
`;
export const StyledDate = styled.div`
margin-top: 10px
width: 100%
`;
export const StyledEmailContentWrapper = styled.div`
border: 1px solid #D9D9D9;
border-radius: 8px;
`;
export const StyledTextArea = styled.textarea`
border: none;
outline: none;
width:100%;
min-height: 200px;
resize:none;
font-family: 'Inter';
font-weight: 400;
font-size: 13px;
`
export const Styledli = styled.li`
  "&:before": {
    color: red !important;
    content: •;
  }
`;
export const StyledFirstli = styled.li`
display:flex;
 flex-direction:column;
`;
export const StyledImg = styled.img`
  max-height: 80%;
  max-width: 80%;
  margin-left: 100px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const StyledInput = styled.input`
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 6px;
  width: 100%;
  max-width: 520px;
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;

export const StyledLabel = styled.p`
  font-size: 12px;
  color: black;
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)`
  margin-left: -30px;
  margin-top: 10px;
  color: gray;
`;
export const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  margin-left: -30px;
  margin-top: 10px;
  color: gray;
`;
export const StyledTxtFieldWrapper = styled.div`
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledPasswordWrapper = styled.div`
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const StyledPswdInnerWrapper = styled.p`
  display: flex;
`;

export const StyledDatePickerWrapper = styled.div`
  display: flex;
`;
export const StyledDateField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  background: #f9f9f9;

  border-radius: 4px;
`;

export const StyledDateSection = styled.div`
  display: flex;
  width: 50%;
  padding-left: 20px;
`;

export const StyledCheckboxSection = styled.div``;
export const StyledImgOuterWrapper = styled.div`
  padding: 10%;
  padding-top: 20px !important;
`;


