import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export const StyledMainWrapper = styled.div`
  //   display: flex;
  color: #2c2c2c;
  padding: 0px 24px; ;
`;

export const StyledMainHeading = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */

  letter-spacing: 0.3px;

  /* grayscale / black */

  color: #252733;
`;

export const StyledMainContainer = styled.div`
  // display:flex;

  background: #ffffff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  // width: 100%;
  min-height: 299px;
  padding: 32px;
`;

export const StyledAddBtn = styled.button`
  font-family: Poppins;
  background: #043e74;
  border-radius: 8px;
  color: white !important;
  border: none;
  padding: 15px;
  font-size: 17px;
`;
export const StyledBtnIcon = styled.img`
  height: 24px;
  margin-right: 12px;
`;
export const StyledBtnTextWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;
export const StyledBtnText = styled.div``;

export const StyledWarningWrapper = styled.div`
  background: #f6f8f9;
  border-radius: 10px;
  padding: 16px;
  width: 80%;
`;
export const StyledWarningText = styled.div``;
export const StyledWarningIcon = styled.img`
  // margin-top:4px;
`;
export const StyledWarningContainer = styled.div`
  // margin-top:4px;
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const StyledTableContainer = styled.div`
  // margin-top:4px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
export const StyledEmailsWrapper = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

export const StyledEmailIcon = styled.img`
  height: 54px;
  width: 68px;
`;
export const StyledEmailIconWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #252c32;
  border-radius: 10px;
  padding: 16px 24px;
  margin-right: 16px;
  cursor: pointer;
`;

export const StyledEmailsHeading = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 8px;

  color: #000000;
`;

export const StyledEmailTypeText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height */

  color: #000000;
  display: flex;
  justify-content: center;
`;

export const StyledCloseIcon = styled.img`
  height: 38px;
  width: 38px;
  margin-top: 10px;
  cursor:pointer;
`;

export const StyledHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeaderRow = styled(TableRow)`
  border: 0px;
  font-weight: bolder !important;
  background-color: #eaecf0 !important;
  color: #043e74 !important;
  background: #fcfcfd !important;
  /* Gray/200 */

  border-bottom: 1px solid #eaecf0;
`;
export const StyledTableHeader = styled(TableHead)`
  // font-family: 'Inter';
  // font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  color: #043e74;
  padding: ;
`;

export const StyledTableHeaderCell = styled.th`
  // font-family: 'Inter';
  // font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  /* identical to box height, or 129% */

  letter-spacing: 0.02em;

  color: #043e74;
  padding-top: 16px;
  padding-bottom: 16px;
`;

export const StyledEmailtypeIcon = styled.img`
  margin: auto;
`;

export const StyledTableCell = styled(TableCell)`
  // font-family: 'Inter';
  // font-style: normal ;
  // font-weight: 400;
  font-size: 12px !important;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #252733 !important;
`;

export const StyledEditIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
  cursor:pointer;
`;
export const StyledEmailTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledGoogleIcon = styled.img`
  margin-right: 8px;
`;

export const StyledSwitch = styled(Switch)`
  color: #12b76a !important;
`;

// Schedule Dialog

export const StyledScheduleDialogWrapper = styled.div`
  // display: flex;
`;

export const StyledDateRangeWrapper = styled.div`
  display: flex;
  background: rgba(249, 249, 249, 0.51);
  border: 0.944298px solid #ededed;
  border-radius: 5.66579px;
  // position: absolute;
  // width: 481px;
  // height: 88px;
  // left: 652px;
  // top: 510px;
  padding: 16px;
`;

export const StyledDateFromWrapper = styled.div`
  margin-right: 16px;
`;
export const StyledDateToWrapper = styled.div``;
export const StyledSecondHeadingWrapper = styled.div`
  margin-top: 32px;
`;

export const StyledSecondHeadingText = styled.p`
  // font-family: "DM Sans";
  // font-style: normal;
  font-weight: 500;
  font-size: 16.4096px;
  line-height: 21px;
  letter-spacing: -0.01em;

  /* Neutrals/2 */

  color: #23262f;
`;

export const StyledScheduleDateWrapper = styled.div`
  background: rgba(249, 249, 249, 0.51);
  border: 0.944298px solid #ededed;
  border-radius: 5.66579px;
  padding: 8px;
  margin-right: 16px;
`;

export const StyledDeleteHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const StyledDeleteHeading = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin: 8px;
  margin-left: 0px !important;
  color: #000000;
`;

export const StyledDateAndTimeSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledEmailScanBtn = styled(Button)`
  padding: 10px 15px !important;

  background: #043e74 !important;
  border-radius: 8px !important;
  color: white !important;
  text-transform: capitalize !important;
  font-family: Poppins !important;
  width: 150px;
  height: 48px;
`;

export const StyledDateLabel = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  // line-height: 21px;
  /* identical to box height */
  margin-top: 0px;
  margin-bottom: 8px;
  color: #333333;
`;

export const StyledButtonWrapper = styled.div`
  padding: 16px;
  padding-bottom: 20px;
`;

export const StyledTabs = styled(Tabs)``;

export const StyledTab = styled(Tab)``;

export const StyledDeleteMessage = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  /* identical to box height, or 210% */

  letter-spacing: -0.01em;

  /* Neutrals/2 */

  color: #23262f;
`;

export const StyledDeleteBtnWrapper = styled.div`
  padding-top: -20px !important;
  padding-bottom 16px !important;
  padding-right:24px !important
 

`;

export const StyledDeleteDialogWrapper = styled.div`
  padding-bottom: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: -20px !important;
  padding-right: 40px !important;
`;

export const StyledDeleteBtn = styled.button`
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  cursor:pointer;
  background: #043e74;
  border-radius: 8px;
  border: 0px !important;
  color: #FFFFFF; !important;
  margin-left:8px;
`;
export const StyledDeleteCancelBtn = styled.button`
  cursor: pointer;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  border-radius: 8px;
  background:#FFFFFF !important;
  color: #043e74;
  border 1px solid #043e74 !important;
`;
export const StyledSearchInput = styled.input`
background: #f9f9f9;
border: 1px solid #ededed;
border-radius: 6px;
width: 230px;
//height: 40px;
outline: rgba(121, 121, 121, 0.16);
  padding: 15px;
`;

export const StyledSearchWrapper = styled.div`
float: right;
margin-bottom:   8px;
padding-right:8px;

`;
