import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { useState, useRef } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import { updateConfigStatus } from "../../stores/slices/configurations-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import Typography from "@mui/material/Typography";

import { AutocompleteGetTagProps } from "@mui/base/AutocompleteUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useCallback } from "react";
// import Button from "@mui/material/Button";
import {Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const SearchKeywords = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [newKeyword, setNewKeyword] = useState("") as any;
  const [newJobTitle, setNewJobTitle] = useState("") as any;
  const [newExcludeKeyword, setNewExcludeKeyword] = useState("") as any;
  const [newExcludeJobTitle, setNewExcludeJobTitle] = useState("") as any;
  const [forAll, setForAll] = useState(false);
  const [applyAllKeywords, setApplyAllKeywords] = useState(false)
  const [applyAllTitles, setAllTitles] = useState(false)

  const dispatch = useDispatch<AppDispatch>() as any;

  const [keywords, setKeywords] = useState(
    JSON.parse(props?.scrapperData?.search_keyword)
  );
  const [jobTitles, setJobTitles] = useState(
    JSON.parse(props.scrapperData.job_title)
  );
  const [allExcludeKeywords, setAllExcludeKeywords] = useState(
    props?.commonData?.search_keyword
  );
  const [excludeJobTitle, setExcludeJobTitle] = useState(
    !props?.scrapperData?.exclude_job_title ||
      props.scrapperData.exclude_job_title === "null" ?
      [] :
      JSON.parse(props?.scrapperData?.exclude_job_title)
  );

  const [excludeKeywords, setExcludeKeywords] = useState(
    !props?.commonData?.exclude_search_keywords ||
      props.commonData.exclude_search_keywords === "null" ?
      [] :
      JSON.parse(props?.scrapperData?.exclude_search_keywords)
  );

  const [allExcludeJobTitle, setAllExcludeJobTitle] = useState(
    props?.commonData?.job_title
  );

  React.useEffect(() => {
    setKeywords(JSON.parse(props?.scrapperData?.search_keyword));
    setJobTitles(JSON.parse(props.scrapperData.job_title));
    setExcludeKeywords(JSON.parse(props?.scrapperData?.exclude_search_keywords));
    setExcludeJobTitle(JSON.parse(props?.scrapperData?.exclude_job_title));
    setAllExcludeKeywords(props.commonData.search_keyword)
    setAllExcludeJobTitle(props?.commonData?.job_title)
  }, [props.scrapperData.search_keyword, props.scrapperData.job_title, props.scrapperData.exclude_search_keywords, props.scrapperData.exclude_job_title, props.commonData.search_keyword, props.commonData.job_title])
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onInputChange = (event: any, value: any) => {
    if (event.key === "Enter") {
      if (keywords === null || keywords === "null") setKeywords([]);
      let kw = [...keywords, newKeyword]
      setKeywords((keywords: any) => kw);

      let obj = {
        id: props.scrapperData.id,
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(kw),
        job_title: JSON.stringify(jobTitles),
        keywords_set_to_all: false,
        delay: props.scrapperData.delay,
        older_lead_threshold: props.scrapperData.older_lead_threshold,
        delay_set_to_all: false,
      };

      dispatch(updateConfigStatus(obj));
    } else {
      setNewKeyword(value);
    }
  };

  const onExcludeInputChange = (event: any, value: any) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      if (excludeKeywords === null || excludeKeywords === "null") setExcludeKeywords([]);
      let kw = [...excludeKeywords, newExcludeKeyword]
      setExcludeKeywords((excludeKeywords: any) => kw);

      let obj = {
        id: props.scrapperData.id,
        exclude_search_keywords: JSON.stringify(kw),
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(keywords),
        job_title: JSON.stringify(jobTitles),
        keywords_set_to_all: false,
        delay: props.scrapperData.delay,
        older_lead_threshold: props.scrapperData.older_lead_threshold,
        delay_set_to_all: false,
      };

      dispatch(updateConfigStatus(obj));
    } else {
      setNewExcludeKeyword(value);
    }


    //response from api
    // .then((res) => {
    //       this.setState({
    //         labels: res
    //       })
    //     })
  };


  const handleExcludeKeywordChipClick = ( value: any) => {
  
    if (excludeKeywords === null || excludeKeywords === "null") {
      setExcludeKeywords([]);
    }
    
    let kw = [...excludeKeywords, value];
    setExcludeKeywords(kw);
  
    let obj = {
      id: props.scrapperData.id,
      exclude_search_keywords: JSON.stringify(kw),
      exclude_job_title: JSON.stringify(excludeJobTitle),
      name: props.scrapperData.name,
      path: props.scrapperData.path,
      status: props.scrapperData.status,
      search_keyword: JSON.stringify(keywords),
      job_title: JSON.stringify(jobTitles),
      keywords_set_to_all: false,
      delay: props.scrapperData.delay,
      older_lead_threshold: props.scrapperData.older_lead_threshold,
      delay_set_to_all: false,
    };
  
    dispatch(updateConfigStatus(obj));
  };

  const handleExcludeTitlesChipClick = ( value: any) => {
  
    if (excludeJobTitle === null || excludeJobTitle === "null") {
      setExcludeJobTitle([]);
    }
    
    let kw = [...excludeJobTitle, value];
    setExcludeJobTitle(kw);
  
    let obj = {
      id: props.scrapperData.id,
      exclude_search_keywords: JSON.stringify(excludeKeywords),
      exclude_job_title: JSON.stringify(kw),
      name: props.scrapperData.name,
      path: props.scrapperData.path,
      status: props.scrapperData.status,
      search_keyword: JSON.stringify(keywords),
      job_title: JSON.stringify(jobTitles),
      
      keywords_set_to_all: false,
      delay: props.scrapperData.delay,
      older_lead_threshold: props.scrapperData.older_lead_threshold,
      delay_set_to_all: false,
    };
  
    dispatch(updateConfigStatus(obj));
  };
  
  const onInputExcludeJobTitlesChange = (event: any, value: any) => {
    if (event.key === "Enter") {
      if (excludeJobTitle === null || excludeJobTitle === "null") setExcludeJobTitle([]);
      let ttl = [...excludeJobTitle, newExcludeJobTitle]
      setExcludeJobTitle((excludeJobTitle: any) => ttl);

      let obj = {
        id: props.scrapperData.id,
        exclude_job_title: JSON.stringify(ttl),
        exclude_search_keywords: JSON.stringify(excludeKeywords),
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(keywords),
        job_title: JSON.stringify(jobTitles),
        keywords_set_to_all: false,
        delay: props.scrapperData.delay,
        older_lead_threshold: props.scrapperData.older_lead_threshold,
        delay_set_to_all: false,
      };

      dispatch(updateConfigStatus(obj));
    } else {
      setNewExcludeJobTitle(value);
    }
  };

  const onInputJobTitlesChange = (event: any, value: any) => {
    if (event.key === "Enter") {
      if (jobTitles === null || jobTitles === "null") setJobTitles([]);
      let ttl = [...jobTitles, newJobTitle]
      setJobTitles((jobTitles: any) => ttl);

      let obj = {
        id: props.scrapperData.id,
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(keywords),
        job_title: JSON.stringify(ttl),
        keywords_set_to_all: false,
        delay: props.scrapperData.delay,
        older_leads_threshold: props.scrapperData.older_leads_threshold,
      };
      dispatch(updateConfigStatus(obj));
    } else {
      setNewJobTitle(value);
    }
  };
  const handleRemoveItem = (value: any) => {
    // console.log("jlfksdjfldffjdslf", value);
  };
  const handleDelete = (value: any) => {
    setKeywords((keywords: any) =>
      keywords?.filter((keyword: any) => keyword !== value)
    );

    let obj = {
      id: props.scrapperData.id,
      name: props.scrapperData.name,
      path: props.scrapperData.path,
      status: props.scrapperData.status,
      search_keyword: JSON.stringify(keywords),
      job_title: JSON.stringify(jobTitles),
      keywords_set_to_all: false,
      delay: props.scrapperData.delay,
      older_leads_threshold: props.scrapperData.older_leads_threshold,
    };
    dispatch(updateConfigStatus(obj));
  };

  const handleJobTitlesDelete = (value: any) => {
    setJobTitles((jobTitles: any) =>
      jobTitles?.filter((jobTitle: any) => jobTitle !== value)
    );
    setTimeout(() => {
      let obj = {
        id: props.scrapperData.id,
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(keywords),
        job_title: JSON.stringify(jobTitles),
        keywords_set_to_all: false,
      };
      dispatch(updateConfigStatus(obj));
    }, 500);
  };

  const handleExcludeKeywordsDelete = (value: any) => {

    const updatedExcludedKeywords = excludeKeywords?.filter((excludeKeyword: any) => excludeKeyword !== value)
    setExcludeKeywords(updatedExcludedKeywords);

    let obj = {
      id: props.scrapperData.id,
      name: props.scrapperData.name,
      path: props.scrapperData.path,
      status: props.scrapperData.status,
      search_keyword: JSON.stringify(keywords),
      job_title: JSON.stringify(jobTitles),
      exclude_search_keywords: JSON.stringify(updatedExcludedKeywords),
      exclude_job_title: JSON.stringify(excludeJobTitle),
      keywords_set_to_all: false,
      delay: props.scrapperData.delay,
      older_leads_threshold: props.scrapperData.older_leads_threshold,
    };
    dispatch(updateConfigStatus(obj));
  };

  const handleExcludeJobTitlesDelete = (value: any) => {
    const updatedJobTitles = excludeJobTitle?.filter((jobTitle: any) => jobTitle !== value)
    setExcludeJobTitle(updatedJobTitles);
    setTimeout(() => {
      let obj = {
        id: props.scrapperData.id,
        name: props.scrapperData.name,
        path: props.scrapperData.path,
        status: props.scrapperData.status,
        search_keyword: JSON.stringify(keywords),
        job_title: JSON.stringify(jobTitles),
        exclude_search_keywords: JSON.stringify(excludeKeywords),
        exclude_job_title: JSON.stringify(updatedJobTitles),
        keywords_set_to_all: false,
      };
      dispatch(updateConfigStatus(obj));
    }, 500);
  };

  const getValues = useCallback(() => {
    if (keywords?.length) {
      return [...keywords?.map((item: any) => item)];
    }
    return props?.scrapperData?.search_keyword === "null" ||
      props?.scrapperData?.search_keyword == null
      ? []
      : [...keywords?.map((item: any) => item)];
  }, [keywords]);

  const getExclueKeywordValues = useCallback(() => {
    if (excludeKeywords?.length) {
      return [...excludeKeywords?.map((item: any) => item)];
    }
    return props?.scrapperData?.exclude_search_keywords === "null" ||
      props?.scrapperData?.exclude_search_keywords == null
      ? []
      : [...excludeKeywords?.map((item: any) => item)];
  }, [excludeKeywords]);

  const getExclueTitleValues = useCallback(() => {
    if (excludeJobTitle?.length) {
      return [...excludeJobTitle?.map((item: any) => item)];
    }
    return props?.scrapperData?.exclude_job_title === "null" ||
      props?.scrapperData?.exclude_job_title == null
      ? []
      : [...excludeJobTitle?.map((item: any) => item)];
  }, [excludeJobTitle]);

  const getJobTitlesValues = useCallback(() => {
    if (jobTitles?.length) {
      return [...jobTitles?.map((item: any) => item)];
    }
    return props?.scrapperData?.job_title == "null" ||
      props?.scrapperData?.job_title == null
      ? []
      : [...jobTitles?.map((item: any) => item)];
  }, [jobTitles]);
  const handleSetToAll = () => {
    setForAll(true);

    let obj = {
      id: props.scrapperData.id,
      name: props.scrapperData.name,
      path: props.scrapperData.path,
      status: props.scrapperData.status,
      search_keyword: JSON.stringify(keywords),
      job_title: JSON.stringify(jobTitles),
      keywords_set_to_all: true,
      delay: props.scrapperData.delay,
      older_leads_threshold: props.scrapperData.older_leads_threshold,
    };
    dispatch(updateConfigStatus(obj));
  };

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="upload picture"
        onClick={handleClickOpen}
        size="small"
        component="label"
      >
        <OpenInFullIcon
          sx={{ fontSize: "12px", color: "#043E74" }}
          fontSize="small"
        />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              padding: "40px",
            },
          },
        }}
      >
        <DialogContent sx={{ overflow: "scroll" }}>
          <Typography
            gutterBottom
            sx={{ marginTop: "16px", fontSize: "16px", fontWeight: "bold" }}
          >
            Search Keywords
          </Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              disableClearable
              options={
                props?.scrapperData?.search_keyword == "null" ||
                  props?.scrapperData?.search_keyword == null
                  ? []
                  : keywords?.map((option: string) => option)
              }
              freeSolo
              value={getValues()}
              limitTags={10}
              onInputChange={onInputChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    // onClick={(e: any, value: any) => handleRemoveItem(value)}
                    onDelete={() => handleDelete(option)}
                    // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                    size="small"
                  />
                ))
              }
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Add Search Keywords" />
              )}
              ChipProps={{
                clickable: false,
                onDelete: handleRemoveItem,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
          <Typography
            gutterBottom
            sx={{ marginTop: "16px", fontSize: "16px", fontWeight: "bold" }}
          >
            Job Titles
          </Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              disableClearable
              filterSelectedOptions
              options={
                props?.scrapperData?.job_title == "null" ||
                props?.scrapperData?.job_title == null
                  ? []
                  : jobTitles?.map((option: string) => option)
              }
              freeSolo
              value={getJobTitlesValues()}
              limitTags={10}
              onInputChange={onInputJobTitlesChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleJobTitlesDelete(option)}
                    size="small"
                  />
                ))
              }
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Add Job Titles" />
              )}
              ChipProps={{
                clickable: false,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => handleSetToAll()}
              sx={{
                backgroundColor: "#043E74",
                borderRadius: "6px",
                color: "#ffff",
              }}
            >
              Set for All
            </Button>
          </Box> */}
          <>
          <Accordion className='accordin-class' sx={{padding: "0px !important"}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Advanced Config
        </AccordionSummary>
        <AccordionDetails>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Exclude Search Keywords</Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="exclude-tags-filled"
              disableClearable
              options={props?.commonData?.search_keyword == "null" || props?.commonData?.search_keyword == null ? [] : allExcludeKeywords?.map((option: any) => option)}
              freeSolo
              value={getExclueKeywordValues()}
              limitTags={10}
              onInputChange={onExcludeInputChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleExcludeKeywordsDelete(option)}
                    size="small"
                  />
                ))
              }
              renderInput={(params) => <TextField {...params} placeholder="Add Keywords to exclude" />}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={selected ? undefined : () => handleExcludeKeywordChipClick(option)}  // Conditionally assigning the onClick handler
                >
                  {option}
                </li>
              )}
              ChipProps={{
                clickable: false,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>Exclude Job Titles</Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="exclude-job-title-tags-filled"
              disableClearable
              options={props?.commonData?.job_title == "null" || props?.commonData?.job_title == null ? [] : allExcludeJobTitle?.map((option: any) => option)}
              freeSolo
              value={getExclueTitleValues()}
              limitTags={10}
              onInputChange={onInputExcludeJobTitlesChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleExcludeJobTitlesDelete(option)}
                    size="small"
                  />
                ))
              }
              renderInput={(params) => <TextField {...params} placeholder="Add Keywords to exclude" />}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={selected ? undefined : () => handleExcludeTitlesChipClick(option)}  // Conditionally assigning the onClick handler
                >
                  {option}
                </li>
              )}
              ChipProps={{
                clickable: false,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
        </AccordionDetails>
      </Accordion>

    </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#043E74" }} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchKeywords;
