import styled from "styled-components";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";

export const StyledLoaderWrapper = styled.div`
  display: flex;
  flex-direction:column !important;
justify-content:center !important;
align-items:center;
margin-top:150px !important;
`;

export const StyledBackIcon = styled.img`
  margin-right: 8px;
`;

export const StyledCloseIcon = styled.img``;
export const StyledIconWrapper = styled.div`
  margin-top: 8px;
  margin-right: 8px;
`;
export const StyledNoRecordWrapper = styled.div`
  display: flex;
  flex-direction:column !important;
justify-content:center !important;
align-items:center;
margin-top:150px !important;
`;
export const StyledTextWrapper = styled.span`
  // margin-left:16px !important;
`;
export const StyledDownloadText = styled.p`
margin:0px;
font-size:14px;
color:#3787FF;
text-decoration;
font-weight:bold
`;
export const StyledTitleWrapper = styled.div`
  margin-top: 8px;
  margin-left: 8px;
`;

export const StyledDownloadWrapper = styled.div`
  margin-right: 32px;
  margin-top: 8px;
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
`;
export const StyledDownloadBtn = styled(Button)`
  
  width: 152px !important;
// height: 40px !important;


background: #043E74 !important;
text-transform:capitalize !important;
border-radius: 6px;
box-shadow:none !important;
margin-top:-8px !important;
`;



export const StyledDownloadIcon = styled.img`


margin-left:8px;

 
`;
export const StyledTableCell = styled(TableCell)`


border:0px !important;
font-size:12px !important;
color:black !important;

 
`;