import styled from "styled-components";


export const StyledLoaderWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
width: "100%";
color: black;
// background-color: rgb(229, 229, 229);
margin:auto;

`;
export const StyledLoaderText = styled.p`
// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: center;
// width: "100%";
color: black;
font-size:40px;
font-weight:bold;
margin-bottom:0px;


`;
export const StyledTextWrapper = styled.div`

display: flex;
flex-direction:column;
justify-content: center;
align-items:center;

color: black;


`;

export const StyledWelcomeMessage = styled.p`
// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: center;
// width: "100%";
color: gray;
font-weight:bold;


`;











export const StyledLogoWrapper = styled.div`
display:flex;

`;
export const StyledLogo = styled.img`
height:50px;
width:50px;
margin-right:-10px !important;

`;