import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import Main from "./components/Main";
import { ErrorBoundary } from "./components";
import { store } from "./stores";

import reportWebVitals from "./reportWebVitals";

// pages
import Dashboard from "./pages/Dashboard";
import Insights from "./pages/Insights";
import Login from "./pages/Login";

import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function wrapWithErrorBoundary(
  component: React.ReactElement,
  trigger?: string
): React.ReactElement {
  return <ErrorBoundary trigger={trigger}>{component}</ErrorBoundary>;
}

root.render(
  <Provider store={store}>
    {/* <Suspense fallback="..."> */}
    {/* <HashRouter>
          <Main>
            <Routes>
              <Route
                path=""
                element={wrapWithErrorBoundary(<Login />, "home")}
              />
              <Route
                path="/Insights"
                element={wrapWithErrorBoundary(<Insights />, "insights")}
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Main>
        </HashRouter> */}
    {/* <ErrorBoundary> */}
    <App />
    {/* </ErrorBoundary> */}

    {/* </Suspense> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
