import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export const StyledInput = styled.input`
  background: #ffffff;
  border: none;
  border-radius: 2px;
  min-width: 50%;
  background-color: rgba(0, 0, 0, 0.06);
  height: 40px;
  &:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
  }
`;
export const StyledButton = styled.button`
//   box-sizing: border-box;

  width: 29px;
  height: 28px;

  background: #0057a9;
  border-radius: 5px;
  padding: inherit;
  box-shadow:none;
  border:none;
  margin-right:6px;

`;
export const StyledIcon = styled(AddIcon)`
  color: white;
  font-size:16px !important;
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  color: white;
  font-size:16px !important;
`;
export const StyledButtonsWrapper = styled.div`
 display:flex;
 flex-direction:column;
 justify-content:center;
`;
export const StyledBtnInnerWrapper = styled.div`

`;
export const StyledWrapper = styled.div`
display:flex
`;
