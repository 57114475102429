import * as React from "react";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Box from "@mui/material/Box";
import {
  StyledFormWrapper,
  StyledPara,
  StyledSecondWrapper,
  StyledHeadingText,
} from "./EmailWarmup.style";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, InputLabel, TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
export default function WarmUpSettings(props: any) {
  const [age, setAge] = React.useState("");
  const [volume, setVolume] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs("2014-08-18T21:11:54")
  );
  const handleChanges = (newValue: Dayjs | null) => {
    setValue(newValue);
  };
  return (
    <div>
      <Box>
        <StyledSecondWrapper>
          <h1 className="Heading-2">Warm-up settings</h1>
          <StyledFormWrapper>
            <StyledHeadingText>
              Choose a mailbox to warm up
            </StyledHeadingText>
            <StyledPara style={{ marginBottom: "20px" }}>
                By adding your email account to a warm-up campaign, you are
                connecting it to a mutual warm-up network with other users,
                meaning your account will be used to warm up other users'
                mailboxes and vice versa.
            </StyledPara>
            <FormControl size="small">
              <Box>
                <InputLabel id="demo-select-small">
                  Select configured email
                </InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={age}
                  label="Select configured email"
                  onChange={handleChange}
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}> quddus.siddiqui@invozon.com</MenuItem>
                  <MenuItem value={20}> muhammad.wasim@invozon.com</MenuItem>
                  <MenuItem value={30}> muhammad.hamza@invozon.com</MenuItem>
                  <MenuItem value={40}>Add new mailbox</MenuItem>
                </Select>
              </Box>
              <StyledHeadingText>Campaign deadline</StyledHeadingText>
              <StyledPara>
                Your warm-up campaign will start immediately after launch and
                end on the chosen date. The longer the warm-up campaign, the
                better results it will yield. We recommend no less than 30 days.
              </StyledPara>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box sx={{ mt: 2 }}></Box>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={value}
                  onChange={handleChanges}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>

              <StyledHeadingText>Schedule (Optional)</StyledHeadingText>
              <StyledPara>
                Specify a sending window for your warm-up campaign. If you
                intend to use this account for drip campaigns at the same time,
                choose a schedule that won't interfere with your email drip
                campaign.
              </StyledPara>
              <Box>
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    color: "#000000",
                    backgroundColor: "rgba(179, 179, 179, 0.32)",
                    borderRadius: "8px",
                  }}
                >
                  Choose a schedule
                </Button>
              </Box>
              <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ border: "1px solid #043E74", color: "#043E74" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    ml: 2,
                    backgroundColor: "#043E74",
                  }}
                  onClick={props.handleNextProp}
                >
                  Next
                </Button>
              </Grid>
            </FormControl>
          </StyledFormWrapper>
        </StyledSecondWrapper>
      </Box>
    </div>
  );
}
