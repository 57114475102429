import React from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import Main from "../components/Main";
// import { ErrorBoundary } from "../components";
// import { store } from "../stores";

// pages
import Dashboard from "../pages/Dashboard";
import Insights from "../pages/Insights";
import Login from "../pages/Login";
import Protected from "./Protected";
import MUISidebar from "../components/SideBar";
import SystemConfigurations from "../pages/SystemConfigurations";
import Preferences from "../pages/Preferences";
import GmailScrapper from "../pages/EmailScanner";

import Loading from "../components/Loader/Loading";
import EmailWarmUp from "../components/EmailWarmup";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// function wrapWithErrorBoundary(
//   component: React.ReactElement,
//   trigger?: string
// ): React.ReactElement {
//   return <ErrorBoundary trigger={trigger}>{component}</ErrorBoundary>;
// }

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Suspense fallback="...">
//         <HashRouter>
//           <Main>
//             <Routes>
//               <Route
//                 path=""
//                 element={wrapWithErrorBoundary(<Login />, "home")}
//               />
//               <Route
//                 path="/Insights"
//                 element={wrapWithErrorBoundary(<Insights />, "insights")}
//               />
//               <Route path="/login" element={<Login />} />
//             </Routes>
//           </Main>
//         </HashRouter>
//       </Suspense>
//     </Provider>
//   </React.StrictMode>
// );

const AppRoutes = () => {
  const token = localStorage.getItem('token');
  
  return (
    <Main>
      <BrowserRouter>
        <Routes>
          <Route path="/loading" element={<Loading />}></Route>
          <Route path="/" element={<Protected Component={MUISidebar} />}>
            <Route
              path="/dashboard"
              element={<Protected Component={Dashboard} />}
            ></Route>
            <Route
              path="/system-configurations"
              element={<Protected Component={SystemConfigurations} />}
            />
            <Route
              path="/preferences"
              element={<Protected Component={Preferences} />}
            />
            <Route
              path="/email-scanner"
              element={<Protected Component={GmailScrapper} />}
            />
             <Route
              path="/email-warmup"
              element={<Protected Component={EmailWarmUp} />}
            />
          </Route>

          <Route
            path="/Insights"
            element={<Protected Component={Insights} />}
          />
          <Route path='/login' element= {<Login />} />
          {/* <Route path='/login' element={ token ? <Dashboard /> : <Login />} /> */}
        </Routes>
      </BrowserRouter>
    </Main>
  );
};

export default AppRoutes;
