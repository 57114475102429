import "./Tabs.css";
import React, { useState, ChangeEvent, useEffect } from "react";
import Box from "@mui/material/Box";

import { styled, alpha } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../stores";
import {
  getScrappersHealth,
  searchScrappers,
  getLeadsCSV,
} from "../../../stores/slices/scrappers-health-slice";

import Pagination from "@mui/material/Pagination";
import { useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "usehooks-ts";

import TableSortLabel from "@mui/material/TableSortLabel";

import { visuallyHidden } from "@mui/utils";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import ScrapperLogs from "../../../components/ScrapperLogs/index";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { SelectChangeEvent } from "@mui/material/Select";
import { StyledSelectWrapper } from "./Tabs.style";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import axios from "axios";

// import { customAxios } from "../../../Services/auth-header";
// import { CSVLink, CSVDownload } from "react-csv";
// import { getFormControlUnstyledUtilityClasses } from "@mui/base";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#F9F9F9",
  color: "#7B7B7B",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const HeaderRow = [
  { label: "Id", dir: "asc", secLabel: "id" },
  { label: "Name", dir: "asc", secLabel: "name" },
  { label: "Leads Generated", dir: "asc", secLabel: "leads_collected" },
  { label: "Duration", dir: "asc", secLabel: "time_taken" },
  { label: "Last Run", dir: "asc", secLabel: "job_ended_at" },
  { label: "Status", dir: "asc", secLabel: "status" },
  { label: "Logs", dir: "asc", secLabel: "logs" },
  { label: "Leads", dir: "asc", secLabel: "scrappeped_leads" },
];

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const HealthTab = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const [from, setFrom] = useState<Date | null>(date);
  const [to, setTo] = useState<Date | null>(new Date());
  const dispatch = useDispatch<AppDispatch>() as any;

  const searchRef = useRef<Text | undefined | any>(undefined);

  const { scrappersHealth, scrapperHealthLoading } = useSelector(
    (state: any) => state.scrappersHealth
  );

  const {
    scrappers_count,
    successfully_run,
    Broken,
    active,
    deactivated,
    active_scrapper_date,
    broken_scrapper_date,
    deactive_scrapper_date,
    scrapper_count_date,
    successfully_run_date,
    active_scrappers_count,
    interrupted_scrapper_count,
  } = scrappersHealth || {};
  const currentStats = [
    {
      key: "Completed ",
      value: successfully_run,
      date: successfully_run_date,
      class: "footerleadItemValue-completed",
    },
    {
      key: "Broken",
      value: Broken,
      date: broken_scrapper_date,
      class: "footerleadItemValue-broken",
    },
    {
      key: "In Progress",
      value: active_scrappers_count,
      date: broken_scrapper_date,
      class: "footerleadItemValue-running",
    },
    {
      key: "Interrupted",
      value: interrupted_scrapper_count,
      date: broken_scrapper_date,
      class: "footerleadItemValue-interrupted",
    },
  ];

  //hard coded values
  const currentStaticStats = [
    {
      key: "Total scrappers",
      value: scrappers_count,
      date: scrapper_count_date,
    },
    {
      key: "Activated",
      value: active,
      date: active_scrapper_date,
    },
    { key: "Deactivated", value: deactivated, date: deactive_scrapper_date },
  ];

  const getPercentage = (value: number) =>
    (value * 100) / (scrappersHealth?.data?.count + interrupted_scrapper_count);

  const [order] = useState("asc");
  const [orderBy] = useState("name");
  const [value, setValue] = useState<string>("");
  const [currentSortValue, setCurrentSortValue] = useState("Name");
  const [age, setAge] = React.useState("10");
  const [scrapperObj, setScrapperObj] = React.useState({
    page: 1,
    order: "job_ended_at",
    from: new Date(`${from}`).toISOString().slice(0, 10),
    to: new Date(`${to}`).toISOString().slice(0, 10),
    perPage: age,
    searchValue: "",
    filter: "none",
  });
  const debouncedValue = useDebounce<string>(value, 500);
  const [sortOrder, setSortOrder] = React.useState("job_ended_at");

  const pageChange = (event: any, page: any) => {
    setScrapperObj({ ...scrapperObj, page: page });
  };
  const createSortHandler = (value: string, dir: string, secLabel: string) => {
    setSortOrder(value);
    HeaderRow.map((row) => {
      if (row.secLabel === secLabel) {
        if (row.dir === "asc") {
          row.dir = "desc";
        } else if (row.dir === "desc") {
          row.dir = "asc";
        }
      }
    });

    if (value === currentSortValue) {
      if (secLabel.includes("-")) {
        secLabel = secLabel.replace("-", "");
        HeaderRow.map((row) => {
          if (row.secLabel === secLabel) {
            row.secLabel = secLabel.replace("-", "");
          }
        });
      } else {
        HeaderRow.map((row) => {
          if (row.secLabel === secLabel) {
            row.secLabel = "-" + secLabel;
          }
        });

        secLabel = "-" + secLabel;
      }
    }
    setCurrentSortValue(value);

    setScrapperObj({ ...scrapperObj, order: secLabel });
    HeaderRow.map((row) => {
      if (row.secLabel === `-${secLabel}`) {
        row.secLabel = secLabel;
      }
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  const [statusValue, setStatusValue] = React.useState("all");

  const handleStatusChange = (event: SelectChangeEvent) => {
    const status =
      event.target.value === "broken"
        ? "broken"
        : event.target.value === "running"
        ? "active"
        : event.target.value === "completed"
        ? "success"
        : event.target.value === "interrupted"
        ? "interrupted"
        : "none";
    setStatusValue(event.target.value as string);
    setScrapperObj({ ...scrapperObj, filter: status });
  };

  const getLogs = () => {
    axios.post("https://mautic-bucket.s3.amazonaws.com/JobInventory.log");
  };
  useEffect(() => {
    const min_date = new Date(`${from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${to}`).toISOString().slice(0, 10);
    let obj = {
      page: 1,
      order: "job_ended_at",

      from: min_date,
      to: max_date,
      perPage: age,
      searchValue: searchRef.current.value,
      filter: scrapperObj.filter,
    };
    dispatch(searchScrappers(obj));
  }, [debouncedValue]);
  useEffect(() => {
    // from?.getDate();
    // to?.toLocaleDateString();

    const min_date = new Date(`${from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${to}`).toISOString().slice(0, 10);
    //specify the date string and the format it's initially in
    // var mydate = moment('15/11/2000', 'DD/MM/YYYY');

    let obj = {
      page: 1,
      order: "job_ended_at",

      from: min_date,
      to: max_date,
      perPage: age,
      searchValue: searchRef.current.value,
      filter: statusValue,
    };

    dispatch(getScrappersHealth(obj));
  }, [from, to, age]);

  const handleRowsChange = (event: { target: { value: string } }) => {
    let newValue = Number(event.target.value);
    setAge(event.target.value);
    // console.log(age, event.target.value);
  };

  const getTime = (mins: number) => {
    let date = new Date(mins);
    date.setMinutes(mins);
    return date.toISOString().substr(11, 8);
  };
  const downloadLeadsFile = (scrapper_name: string, job_ended_at: string) => {
    // text content
    // const texts = ["line 1", "line 2", "line 3"];

    // file object
    // const file = new Blob(texts, { type: "text/plain" });

    // anchor link
    // const element = document.createElement("a");
    // element.href = URL.createObjectURL(file);
    // element.download = `${scrapperName}` + "'s leads " + Date.now() + ".txt";

    // simulate link click
    // document.body.appendChild(element); // Required for this to work in FireFox
    // element.click();
    const leads_date = new Date(`${job_ended_at}`).toISOString().slice(0, 10);

    function jsonToCSV(json: any) {
      if (!json.length) return '';
  
      const keys = Object.keys(json[0]);
      const csvRows = [keys.join(',')]; // Header row from keys
  
      for (const row of json) {
          const values = keys.map(key => {
              const escaped = ('' + row[key]).replace(/"/g, '\\"'); // Escape double quotes
              return `"${escaped}"`;
          });
          csvRows.push(values.join(','));
      }
  
      return csvRows.join('\n');
  }

    let obj = {
      scrapper_name,
      leads_date,
    };
    const data = dispatch(getLeadsCSV(obj));
    // console.log(data)
    data.then((response: any) => {
      console.log(response);
      const csvData = jsonToCSV(response?.payload?.data);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${scrapper_name + " " + leads_date}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // Clean up
  });
  
  };

  const getCount = () => {
    if (scrappersHealth?.data?.count) {
      let count = Math.ceil(scrappersHealth?.data?.count / parseInt(age));
      return count;
    } else {
      return 10;
    }
  };

  useEffect(() => {
    dispatch(getScrappersHealth(scrapperObj));
  }, [scrapperObj]);

  useEffect(() => {
    // dispatch(scrappersStats());
    // dispatch(getScrappersHealth(scrapperObj));
  }, []);
  useEffect(() => {
    // customAxios
    //   .get(
    //     "https://staging-api-mautic.invo.zone/api/stats/scrappers_stats_leads/?date=2022-10-15&scrapper_name=jobvertise"
    //   )
    //   .then((res) => console.log(res));
  }, []);

  return (
    <div className="tabContainer">
      <div className="health-Header">
        <div className="select-Date">
          <label className="dateLable">From:</label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={from}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setFrom(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    ref={inputRef}
                    {...inputProps}
                    style={{
                      border: "0px",
                      width: "70px",
                    }}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
          <label className="dateLable" style={{ marginLeft: "20px" }}>
            To:
          </label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={to}
              inputFormat="dd/MM/yyyy"
              onChange={(newValue) => {
                setTo(newValue);
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input
                    ref={inputRef}
                    {...inputProps}
                    style={{
                      border: "0px",
                      width: "70px",
                    }}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex" }}>
          <StyledSelectWrapper>
            {/* <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Age</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={agevalue}
                label="Age"
                onChange={handleStatusChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl> */}
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={statusValue}
              onChange={handleStatusChange}
              input={<BootstrapInput />}
              sx={{ height: "inherit", width: "inherit" }}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"running"}>Running</MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
              <MenuItem value={"broken"}>Broken</MenuItem>
              <MenuItem value={"interrupted"}>Interrupted</MenuItem>
            </Select>
          </StyledSelectWrapper>

          {/* <StyledSelectWrapper>
            <Select value={agevalue}>
              <StyledOption value={10}>Ten</StyledOption>
              <StyledOption value={20}>Twenty</StyledOption>
              <StyledOption value={30}>Thirty</StyledOption>
            </Select>
          </StyledSelectWrapper> */}
          <Search>
            <input
              className="searchinput"
              placeholder="Search by scrapper name..."
              ref={searchRef}
              value={value}
              onChange={handleChange}
            />
          </Search>
        </div>
      </div>
      <div className="lead-body" style={{ display: "flex" }}>
        <TableContainer
          component={Paper}
          sx={{
            background: "#fffff",
            border: "1px solid #e5e5e5",
            padding: "20px",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {scrapperObj.searchValue}
          {scrapperHealthLoading && scrapperObj.searchValue === "" ? (
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "20px",
              }}
            >
              <CircularProgress size={80} />
            </Box>
          ) : (
            <Table
              sx={{
                minWidth: 650,
                border: 0,
                borderCollapse: "separate",
                borderSpacing: "0px 8px",
                width: "100%",
              }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow sx={{ border: 0, fontWeight: "bolder" }}>
                  {HeaderRow.map((row, index) => (
                    <TableCell
                      align={index <= 1 ? "left" : "center"}
                      sx={{ border: "none", fontWeight: "bold" }}
                      sortDirection={"desc"}
                      key={index}
                    >
                      {index < 6 ? (
                        <TableSortLabel
                          active={orderBy === row.label}
                          direction={row.dir === "asc" ? "asc" : "desc"}
                          onClick={() =>
                            createSortHandler(row.label, row.dir, row.secLabel)
                          }
                        >
                          {row.label}
                          {orderBy === "name" ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        row.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody sx={{ maxHeight: "600px", overflow: "scroll" }}>
                {scrappersHealth?.data?.data?.map(
                  (scrapper: any, index: number) => (
                    <TableRow
                      key={scrapper.id}
                      sx={{
                        "&:first-child": {
                          borderRadius: "5px",
                        },
                        height: 10,
                        background:
                          scrapper.status === "Running"
                            ? "#E7F5FF"
                            : scrapper.status === "Completed"
                            ? "#23AF0033"
                            : scrapper.status == "Interrupted"
                            ? "#ffecca"
                            : "#C80B0B33",
                      }}
                    >
                      <TableCell sx={{ border: 0 }}>{scrapper.id}</TableCell>
                      <TableCell component="th" scope="row" sx={{ border: 0 }}>
                        {scrapper.name}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {scrapper.leads_collected}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {getTime(scrapper.time_taken)}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {scrapper.job_ended_at ? scrapper.job_ended_at : "N/A"}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {scrapper.status ? scrapper.status : "N/A"}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {/* <ScrapperLogs logs={scrapper.name} /> */}

                        <a
                          href="https://mautic-bucket.s3.amazonaws.com/JobInventory.log"
                          download
                        >
                          <TextSnippetIcon
                            sx={{ color: "#043E74", cursor: "pointer" }}
                          ></TextSnippetIcon>
                        </a>
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="center">
                        {scrapper.status === "Running" ? (
                          <LeaderboardIcon
                            sx={{
                              color: "gray",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                          ></LeaderboardIcon>
                        ) : (
                          <LeaderboardIcon
                            sx={{
                              color: "#043E74",
                              fontSize: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              downloadLeadsFile(
                                scrapper.name,
                                scrapper.job_ended_at
                              )
                            }
                          ></LeaderboardIcon>
                        )}
                        {/* <LeaderboardIcon
                          sx={{ color: "#043E74", fontSize: "16px" }}
                          onClick={() =>
                            downloadLeadsFile(
                              scrapper.name,
                              scrapper.job_ended_at
                            )
                          }
                        ></LeaderboardIcon> */}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          )}

          <Box sx={{ display: "flex" }}>
            <Pagination
              // count={Math.ceil(scrappersHealth?.data?.count / parseInt(age))}
              count={getCount()}
              showFirstButton
              showLastButton
              onChange={(event: any, page: any) => pageChange(event, page)}
              variant="outlined"
              shape="rounded"
              size="small"
              sx={{ mt: "8px" }}
            />
            <Box>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={age}
                onChange={handleRowsChange}
                input={<BootstrapInput />}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Box>
          </Box>
        </TableContainer>

        <div
          style={{ display: "flex", justifyContent: "end", padding: "8px" }}
        ></div>
      </div>

      <div className="lead-footer">
        <label className="footer-leading">Scrappers Status</label>
        <div className="footerlead">
          {currentStats.map((state, index) => (
            <div
              className="footerleadItem"
              style={{ color: "black" }}
              key={index}
            >
              <div className="footerleadHeader">
                <label className="footerleadItemTitle">{state.key}</label>
                <label className="footerleadItempercent">
                  {state.key !== "Number of scrappers"
                    ? Math.round(getPercentage(state.value)) + "%"
                    : ""}
                </label>
              </div>
              <label className={state?.class}>{state.value}</label>
              <div
                style={{
                  color: "#C0C0C0",

                  display: "flex",
                  fontSize: "12px",
                  minHeight: "20px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                {state.date ? "Date: " + state.date : ""}
              </div>
            </div>
          ))}
        </div>
        <br style={{ marginTop: "40px" }} />
        <label className="footer-leading">Configurations</label>
        <div className="footerlead">
          {currentStaticStats.map((state: any, index: number) => (
            <div className="footerleadItem" key={state.key}>
              <div className="footerleadHeader">
                <label className="footerleadItemTitle">{state.key}</label>
                <label className="footerleadItempercent">
                  {Math.round(getPercentage(state.value)) + "%"}
                </label>
              </div>
              <label className="footerleadItemValue">{state.value}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HealthTab;
