import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAxios } from "../../Services/auth-header";


// export const leadsStats = createAsyncThunk(
//   "leads/leadsStates",
//   async (user, thunkAPI) => {
//     try {
//       const response = await customAxios.get(
//         `${process.env.REACT_APP_BASE_URL}stats/leads_stats/`
//       );
//       return response.data;
//     } catch (err) {
//       console.log(err);
//     }
//   }
// );

export const emailsStatsGraph = createAsyncThunk(
  "stats/email",
  async (obj: any, thunkAPI) => {
    try {
      const url:string =obj.scrapper_name? `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph?min_date=${
        obj.from ? obj.from : ""
      }&max_date=${obj.to ? obj.to : ""}&scrapper_name=${obj.scrapper_name}`:`${process.env.REACT_APP_BASE_URL}stats/email?min_date=${
        obj.from ? obj.from : ""
      }&max_date=${obj.to ? obj.to : ""}&db_name=${obj.db_name}`
     
      const response = await customAxios.get(
        url
      );
    //   console.log(response)

      return response.data;
    } catch (err) {}
  }
);

export const getNamesList = createAsyncThunk(
  "leads/getNamesList",
  async (user, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/scrappers_name_list/`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);


export const getScrapperByName = createAsyncThunk(
    "leads/getScrapperByName",
    async (name: string, thunkAPI) => {
      try {
        const response = await customAxios.get(
          `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph?scrapper_name=${name}`
        );
        return response.data;
      } catch (err) {}
    }
  );

const initialState = {
  email_stats: {},


  err: "",
  success: false,
  emailsGraphData: {},
  namesList: [],
  emailGraphLoader:false
};

const emailsStatsSlice = createSlice({
  name: "emails",
  initialState,
  reducers: {
    emails_stats: (state, { payload }) => {
    //   state.emails_stats = payload;
    },
  },
  extraReducers: {
    // [leadsStats.pending.type]: (state: any, action: any) => ({
    //   ...state,
    // }),
    // [leadsStats.fulfilled.type]: (state: any, action: any) => ({
    //   ...state,
    //   leads_stats: action.payload,
    //   success: true,
    // }),
    // [leadsStats.rejected.type]: (state: any, action: any) => ({
    //   ...state,
    //   err: action.payload.error,
    //   success: false,
    // }),

    [emailsStatsGraph.pending.type]: (state: any, action: any) => ({
      ...state,
      emailGraphLoader:true
    }),
    [emailsStatsGraph.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      emailsGraphData: action.payload,
      success: true,
      emailGraphLoader:false
    }),
    [emailsStatsGraph.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    // [getScrapperByName.pending.type]: (state: any, action: any) => ({
    //     ...state,
    //   }),
    //   [getScrapperByName.fulfilled.type]: (state: any, action: any) => ({
    //     ...state,
    //     leadsGraphData: action.payload,
    //     success: true,
    //   }),
    //   [getScrapperByName.rejected.type]: (state: any, action: any) => ({
    //     ...state,
    //     err: action.payload.error,
    //     success: false,
    //   }),

    // [getNamesList.pending.type]: (state: any, action: any) => ({
    //   ...state,
    // }),
    // [getNamesList.fulfilled.type]: (state: any, action: any) => ({
    //   ...state,
    //   namesList: action.payload,
    //   success: true,
    // }),
    // [getNamesList.rejected.type]: (state: any, action: any) => ({
    //   ...state,
    //   err: action.payload.error,
    //   success: false,
    // }),
  },
});

export default emailsStatsSlice.reducer;
