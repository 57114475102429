import "./Tabs.css";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { Bar } from "react-chartjs-2";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";
import { emailsStatsGraph } from "../../../stores/slices/email-stats-slice";
import { useDispatch, useSelector } from "react-redux";
import {
  StyledLoaderWrapper,
  StyledDBIconWrapper,
  StyledDBLabelWrapper,
  StyledLabel,
  StyledValueLable,
  StyledValueLableWrapper,
  StyledValueLableText,
} from "./Tabs.style";
import { AppDispatch } from "../../../stores";
import CircularProgress from "@mui/material/CircularProgress";
import InvoGames from "../../../assets/icon/InvoGames Logo fav.svg";
import InvoZoneLogo from "../../../assets/icon/InvoZone Logo fav.svg";
import InvoBlox from "../../../assets/icon/invobox fav.svg";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const options = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};
let labels: any = [];
const EmailsTab = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const [from, setFrom] = useState<Date | null>(date);
  const [to, setValue] = useState<Date | null>(new Date());
  const dispatch = useDispatch<AppDispatch>() as any;
  const { emailsGraphData, emailGraphLoader } = useSelector(
    (state: any) => state.emailStats
  );

  const { currentDB } = useSelector((state: any) => state.leadsStats);

  const getFirstDataSet = useCallback(() => {
    let localDataSet: any = [];
    emailsGraphData?.sent_mails?.map((lead: any) =>
      localDataSet.push(lead.num_records)
    );

    labels = [];
    // emailsGraphData?.sent_mails?.map((email: any) => {
    //   labels.push(email.date_sent__date);
    // });
    labels = emailsGraphData?.date_range;
    // console.log("returningfrestdatasetttt", localDataSet);
    return emailsGraphData?.sent_mails;
  }, [emailsGraphData])();

  const getSecondDataSet = useCallback(() => {
    let localDataSet: any = [];
    emailsGraphData?.failed_mails?.map((lead: any) =>
      localDataSet.push(lead.num_records)
    );

    return emailsGraphData?.failed_mails;
  }, [emailsGraphData])();

  const getThirdDataSet = useCallback(() => {
    let localDataSet: any = [];
    emailsGraphData?.read_mails?.map((lead: any) =>
      localDataSet.push(lead.num_records)
    );

    return emailsGraphData?.read_mails;
  }, [emailsGraphData])();

  // console.log("emailsstats", emailsGraphData);

  const data = {
    labels,
    datasets: [
      {
        label: "Sent Emails",
        data: getFirstDataSet,

        backgroundColor: "#A6D702",
        // borderColor: " #3751FF",
        stacked: true,
        maxBarThickness: 50,
        hoverBackgroundColor: "#A6D702",
      } as any,
      {
        label: "Read Emails",
        data: getThirdDataSet,

        backgroundColor: "#009734",
        // borderColor: "#009734",
        stacked: true,
        maxBarThickness: 50,
        hoverBackgroundColor: "#009734",
      } as any,
      {
        label: "Failed Emails",
        data: getSecondDataSet,

        backgroundColor: "#E24626",
        // borderColor: " #E24626",
        stacked: true,
        maxBarThickness: 50,
        hoverBackgroundColor: "#009734",
      } as any,
    ],
  };
  const getcurrentDB = () =>
    currentDB === "mautic4_db"
      ? "InvoZone"
      : currentDB === "invoblox_db"
      ? "InvoBlox"
      : "InvoGames";
  const getcurrentDBSrc = () =>
    currentDB === "mautic4_db"
      ? InvoZoneLogo
      : currentDB === "invoblox_db"
      ? InvoBlox
      : InvoGames;

  const currentStats = [
    {
      key: "Sent Emails",
      value: emailsGraphData?.sent_email_count,
      class: "footerleadItemValue-sent",
    },
    {
      key: "Read Emails",
      value: emailsGraphData?.read_email_count,
      class: "footerleadItemValue-read",
    },
    {
      key: "Failed Emails",
      value: emailsGraphData?.fail_email_count,
      class: "footerleadItemValue-failed",
    },
  ];

  useEffect(() => {
    labels = [];
    emailsGraphData?.sent_mails?.map((email: any) => {
      labels.push(email.date_sent__date);
    });
  }, [emailsGraphData]);
  useEffect(() => {
    // from?.getDate();
    // to?.toLocaleDateString();

    const min_date = new Date(`${from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${to}`).toISOString().slice(0, 10);
    //specify the date string and the format it's initially in
    // var mydate = moment('15/11/2000', 'DD/MM/YYYY');

    let obj = {
      from: min_date,
      to: max_date,
      db_name: currentDB,
    };

    dispatch(emailsStatsGraph(obj));
    data.datasets[2].data = emailsGraphData?.failed_mails;
    data.datasets[0].data = emailsGraphData?.read_mails;
    data.datasets[1].data = emailsGraphData?.sent_mails;
  }, [from, to, currentDB]);

  useEffect(() => {
    // console.log("emailsgraphdata", emailsGraphData);
  }, [emailsGraphData]);
  useEffect(() => {
    const min_date = new Date(`${from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${to}`).toISOString().slice(0, 10);

    let obj = {
      from: min_date,
      to: max_date,
    };
    dispatch(emailsStatsGraph(obj));
    data.datasets[2].data = emailsGraphData?.failed_mails;
    data.datasets[0].data = emailsGraphData?.read_mails;
    data.datasets[1].data = emailsGraphData?.sent_mails;

    // setTimeout(() => {
    //   console.log(emailsGraphData);
    // }, 10000);
  }, []);

  return (
    <div className="tabContainer">
      <div className="lead-Header">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
            paddingRight: "40px",
          }}
        >
          <div style={{ display: "flex" }}>
            <StyledValueLable>
              <StyledValueLableWrapper>
                <StyledValueLableText> Sent Date</StyledValueLableText>
              </StyledValueLableWrapper>
            </StyledValueLable>
            <div className="select-Date-leads">
              <label className="dateLable">From:</label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={from}
                  onChange={(newValue) => {
                    setFrom(newValue);
                  }}
                  inputFormat="dd/MM/yyyy"
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input
                        ref={inputRef}
                        {...inputProps}
                        style={{
                          border: "0px",
                          width: "70px",
                        }}
                      />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>

              <label className="dateLable" style={{ marginLeft: "20px" }}>
                To:
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={to}
                  inputFormat="dd/MM/yyyy"
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={({ inputRef, inputProps, InputProps }) => (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <input
                        ref={inputRef}
                        {...inputProps}
                        style={{
                          border: "0px",
                          width: "70px",
                        }}
                      />
                      {InputProps?.endAdornment}
                    </Box>
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
          <StyledDBLabelWrapper>
            <StyledDBIconWrapper src={getcurrentDBSrc()}></StyledDBIconWrapper>
            <StyledLabel>{getcurrentDB()}</StyledLabel>
          </StyledDBLabelWrapper>
        </div>
      </div>
      <div className="lead-body">
        {emailGraphLoader && (
          <StyledLoaderWrapper>
            <CircularProgress size={200} />
          </StyledLoaderWrapper>
        )}
        {/* <Line
          options={options}
          data={data}
          style={{ opacity: emailGraphLoader ? 0.2 : 1 }}
        /> */}

        <Bar
          options={options}
          data={data}
          style={{ opacity: emailGraphLoader ? 0.2 : 1 }}
        ></Bar>
      </div>
      <div className="lead-footer">
        <label className="footer-leading">Emails</label>
        <div className="footerlead">
          {currentStats.map((state) => (
            <div className="footerleadItem" key={state.key}>
              <div className="footerleadHeader">
                <label className="footerleadItemTitle">{state.key}</label>
                <label className="footerleadItempercent">
                  {/* {Math.round(getPercentage(state.value)) + "%"} */}
                </label>
              </div>
              <label className={state?.class}>{state.value}</label>
              <div
                style={{
                  color: "#C0C0C0",

                  display: "flex",
                  fontSize: "12px",
                  minHeight: "20px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                {/* {state.date ? "Date: " + state.date : ""} */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailsTab;
