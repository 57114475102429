import * as React from "react";
import {
  StyledFormWrapper,
  StyledPara,
  StyledSecondWrapper,
  StyledHeadingText,
  StyledEmailContentWrapper,
  StyledTextArea,
} from "./EmailWarmup.style";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MixedEmail from "../../assets/icon/mixedEmail.svg";
import SpecificEmail from "../../assets/icon/specificEmail.svg";
import AIPowered from "../../assets/icon/AIPowered.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Button,
  CardActionArea,
  Chip,
  Divider,
  Grid,
  TextareaAutosize,
} from "@mui/material";
export default function EmailContent(props: any) {
  return (
    <div>
      <Box>
        <StyledSecondWrapper>
          <h1 className="Heading-2">Select your warm-up strategy</h1>
          <StyledFormWrapper>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} md={3} lg={4}>
                <Card sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={AIPowered}
                      height="160"
                      sx={{ padding: "10px" }}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid container>
                        <Grid item className="Card-Heading">
                          AI-Powered
                        </Grid>
                      </Grid>
                      <Grid container className={"Card-Content"}>
                        Each email will contain unique algorithm-generated text.
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Card sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={SpecificEmail}
                      height="160"
                      sx={{ padding: "10px" }}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          item
                          lg={6}
                          xs={6}
                          md={6}
                          className="Card-Heading"
                        >
                          Specific
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          lg={6}
                          xs={6}
                          md={6}
                        >
                          <InfoOutlinedIcon />
                        </Grid>
                      </Grid>
                      <Grid container className={"Card-Content"}>
                        Improve deliverability for specific email templates.
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Card sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}>
                  <CardActionArea>
                    <Grid
                      container
                      justifyContent="flex-end"
                      sx={{ paddingTop: "10px" }}
                    >
                      <Chip label="Coming Soon" variant="outlined" />
                    </Grid>
                    <CardMedia
                      component="img"
                      image={MixedEmail}
                      height="120"
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid container>
                        <Grid item className="Card-Heading">
                          Mixed
                        </Grid>
                      </Grid>
                      <Grid container className={"Card-Content"}>
                        Combine both strategies for all-round deliverability
                        improvement.
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            <Box>
              <StyledHeadingText>
                Email content
              </StyledHeadingText>
              <StyledPara>
                  Using a complex language model, our algorithm creates lifelike
                  messages for both warm-up emails and replies. This makes it
                  impossible for email providers to tell your warm-up campaign
                  from a real dialogue, so your email reputation and
                  deliverability will grow as naturally as possible. Here's an
                  example:
              </StyledPara>
            </Box>
            <StyledEmailContentWrapper>
              <h1 className="Heading-2">Following up on our talk - snv</h1>
              <StyledTextArea rows={5} cols={100} value={` 
Good morning Arthur, 
How are you? 

In the article below you can find out more information on the topic from our last conversation: "Dietary supplementation of ascorbic acid in iron-limited fishmeal-fed Atlantic salmon resulted in higher blood concentrations and higher transfer rates of iron for the growth and performance of Atlantic salmon. Fish fed an iron-restricted diet containing 1. 5 g/100 g Fe per day had a higher blood concentration of transferrin-bound iron, with higher transferrin saturation and ferritin concentration and lower transferrin content compared with those fed a control diet. Dietary supplementation of ascorbic acid (10 and 20 g/kg) enhanced iron content and decreased the iron concentration" 

All the best, 

Robert Ramirez Farlon`}>  </StyledTextArea> 
             
       

              <Divider></Divider>
              <Box sx={{ mt: 2 }}>
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    color: "#000000",
                    backgroundColor: "rgba(179, 179, 179, 0.32)",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                >
                  Generate another email
                </Button>
              </Box>
            </StyledEmailContentWrapper>
            <Grid container justifyContent="flex-end" sx={{ mb: 2, mt: 4 }}>
              <Button
                variant="outlined"
                size="large"
                sx={{ border: "1px solid #043E74", color: "#043E74" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                sx={{
                  ml: 2,
                  backgroundColor: "#043E74",
                }}
                onClick={props.handleNextProp}
              >
                Next
              </Button>
            </Grid>
          </StyledFormWrapper>
        </StyledSecondWrapper>
      </Box>
    </div>
  );
}
