import "./Tabs.css";

const ScrapperLeadsTab = () => {
  const googleSheetLink =
    "https://docs.google.com/spreadsheets/d/1Zl96Mha0Gy7pJ1dolkvx6el9K-nYVB_ljT3DGaw3I9Q/edit";

  return (
    <div className="google-sheet-embed">
      <iframe
        title="Embedded Google Sheet"
        src={googleSheetLink}
        width="100%"
        height="600px"
        frameBorder="0"
        allowFullScreen={true}
        loading="eager"
      ></iframe>
    </div>
  );
};

export default ScrapperLeadsTab;
