import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export const StyledInput = styled.input`
background: #FFFFFF;
border: 1px solid #DFE0EB;
border-radius: 6px;
min-width:50%;
height:40px;
&:focus {
    outline: none;
    // box-shadow: 0px 0px 2px red;
    // border: 1px solid #DFE0EB;
}

`;