// import styled from "styled-components";
import "../pages/Dashboard/dasboard.css";
// import { ICON } from "../assets/icon";
// import { AiOutlineSearch } from "react-icons/ai";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import ListSubheader from "@mui/material/ListSubheader";
import InvoGames from "../assets/icon/InvoGames Logo fav.svg";
import InvoZoneLogo from "../assets/icon/InvoZone Logo fav.svg";
import InvoBlox from "../assets/icon/invobox fav.svg";
import { StyledIcon } from "./index.style";
import { useDispatch, useSelector } from "react-redux";
import { selectDB } from "../stores/slices/leads-slice";
import { AppDispatch } from "../stores";
import { loggedOut } from "../stores/slices/login-slice";

interface Props {}
const ProfileHeader = ({}: Props) => {
  const [currentTab, setCurrentTab] = React.useState(1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>() as any;
  const { showAccounts } = useSelector((state: any) => state.leadsStats);
  const { loggedInUser } = useSelector((state: any) => state.LoginReducer);
  let userObj = localStorage.getItem("userObj") as any;
  userObj = JSON.parse(userObj);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (value: number) => {
    setCurrentTab(value);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userObj");
    navigate("/login", { replace: true });
    dispatch(loggedOut());
  };

  React.useEffect(() => {
    dispatch(selectDB(currentTab));
  }, [currentTab]);

  return (
    <div className="profile-header">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label className="headingText">Overview</label>
        <label className="headingParagraph ">Business Development</label>
      </div>
      <div className="profile-header-right">
        {/* <AiOutlineSearch color="#C5C7CD" /> */}

        {/* <img
          src={ICON.notification}
          style={{
            width: "14px",
            height: "16px",
            marginRight: "40px",
            marginLeft: "10px",
            objectFit: "contain",
          }}
        />

        <img
          src={ICON.profile}
          style={{
            width: "33px",
            height: "33px",
            objectFit: "contain",
            borderRadius: "50%",
          }}
        /> */}
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32 }}
                src={userObj?.picture}
              ></Avatar>
            </IconButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              width: "180px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <Avatar /> Profile
          </MenuItem>
          {/* <MenuItem>
            <Avatar /> My account
          </MenuItem> */}
          <Divider />
          {/* <MenuItem>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem> */}
          {showAccounts && (
            <Box>
              <ListSubheader>Accounts</ListSubheader>

              <MenuItem
                onClick={() => handleTabChange(1)}
                selected={currentTab == 1}
              >
                <ListItemIcon>
                  <StyledIcon src={InvoZoneLogo}></StyledIcon>
                </ListItemIcon>
                InvoZone
              </MenuItem>

              <MenuItem
                onClick={() => handleTabChange(2)}
                selected={currentTab == 2}
              >
                <ListItemIcon>
                  <StyledIcon src={InvoBlox}></StyledIcon>
                </ListItemIcon>
                InvoBlox
              </MenuItem>
              <MenuItem
                onClick={() => handleTabChange(3)}
                selected={currentTab == 3}
              >
                <ListItemIcon>
                  {/* <Settings fontSize="small" /> */}
                  <StyledIcon src={InvoGames}></StyledIcon>
                </ListItemIcon>
                InvoGames
              </MenuItem>
              <Divider />
            </Box>
          )}

          <MenuItem>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => logOut()}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ProfileHeader;
