import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginWithEmailPassword } from "../../stores/slices/login-slice"; // Update path accordingly
import { useNavigate } from "react-router-dom";
import {
  StyledLoginWrapper,
  StyledGoogleLoginWrapper,
  StyledTextWrapper,
  StyledLogo,
  StyledWelcomeMessage,
  StyledGoogleBtn,
  StyledGoogleIcon,
  StyledBtnText,
  StyledGoodleBtnWrapper,
  StyledSigninMessage,
  StyledCard,
  StyledInput
} from "./Login.style";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import InvoStreakLogo from "../../assets/icon/Favicon.png";
import GoogleBtn from "../../assets/icon/googlebtn.png";

const GoogleLogin = () => {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const { success, user, token, redirectUrl } = useSelector((state: any) => state.LoginReducer);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await dispatch(loginWithEmailPassword({ username, email, password }));
  };
  const handleGoogleLogin = () => {
    let originURL = window.location.origin;
    window.location.replace(
      `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${originURL}/loading&prompt=consent&response_type=token&client_id=365043794676-h5b52ihsoovq58mbfqof83l50poerc4u.apps.googleusercontent.com&scope=openid%20email%20profile`
    );
  };


  useEffect(() => {

    if (success ) {
      window.location.replace(redirectUrl);
      // navigate("/Dashboard");
    }
  }, [success, navigate]);
  useEffect(()=>{
    if (token ) {
      navigate("/Dashboard");
    }
  },[])
  return (
    <StyledGoogleLoginWrapper>
      <StyledCard>
        <React.Fragment>
          <CardContent>
            <StyledTextWrapper>
              <StyledLogo src={InvoStreakLogo}></StyledLogo>
              <StyledWelcomeMessage>
                Welcome to InvoStreak
              </StyledWelcomeMessage>
              <StyledSigninMessage>Login to continue</StyledSigninMessage>
            </StyledTextWrapper>
            <StyledGoodleBtnWrapper>
                <StyledGoogleBtn>
                  <StyledGoogleIcon src={GoogleBtn}></StyledGoogleIcon>
                  <StyledBtnText onClick={() => handleGoogleLogin()}>
                    Continue with Google
                  </StyledBtnText>
                </StyledGoogleBtn>
              </StyledGoodleBtnWrapper>


            {/* <form onSubmit={handleLogin}>
            <StyledInput
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Username"
              />
              <StyledInput
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
              <StyledInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <StyledGoogleBtn type="submit">Login</StyledGoogleBtn>
            </form> */}
          </CardContent>
          <CardActions></CardActions>
        </React.Fragment>
      </StyledCard>
    </StyledGoogleLoginWrapper>
  );
};

const Login = () => {

  return (
    <StyledLoginWrapper>
      <GoogleLogin />
    </StyledLoginWrapper>
  );
};

export default Login;
