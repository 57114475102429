import React, {ChangeEvent, useRef, useState} from 'react'
import {
    StyledAddBtn,
    StyledBtnIcon,
    StyledBtnText,
    StyledBtnTextWrapper,
    StyledMainContainer,
    StyledMainHeading,
    StyledMainWrapper,
    StyledTableContainer,
    StyledSearchInput, StyledSearchWrapper
} from './EmailScanner.style'
import AddBtnIcon from '../../assets/icon/addbtn.png'
import EmailSelectDialog from "./EmailSelectDialog"
import EmailsTable from './EmailsTable'

const EmailScanner = () => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = useState<string>("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };
    return (
        <StyledMainWrapper>
            <StyledMainHeading>Email Scanner</StyledMainHeading>
            <StyledMainContainer>
                <StyledAddBtn onClick={() => handleClickOpen()}>
                    <StyledBtnTextWrapper>
                        <StyledBtnIcon src={AddBtnIcon}></StyledBtnIcon>
                        <StyledBtnText>
                            Add email address
                        </StyledBtnText>
                    </StyledBtnTextWrapper>
                </StyledAddBtn>
                <StyledSearchWrapper>
                    <StyledSearchInput
                        value={value}
                        onChange={handleChange}
                        placeholder="Search..."></StyledSearchInput>
                </StyledSearchWrapper>
                <StyledTableContainer>
                    <EmailsTable filterValue={value}/>
                </StyledTableContainer>
            </StyledMainContainer>


            <EmailSelectDialog isOpen={open} handleClose={() => handleClose()}/>

        </StyledMainWrapper>
    )
}
export default EmailScanner

