import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  StyledEditIcon,
  StyledEmailTypeWrapper,
  StyledGoogleIcon,
  StyledHeaderRow,
  StyledTableCell,
  StyledTableHeader,
  StyledTableHeaderCell,
} from "./EmailScanner.style";
import GoogleIcon from "../../assets/icon/google-svgrepo-com.svg";
import Scheduledialog from "./ScheduleDialog";
import DeleteAlertDialog from "./DeleteEmailDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../stores";
import { getEmailScanner } from "../../stores/slices/email-scanner-slice";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import trashNormal from "../../assets/icon/delete.svg";
import trashRed from "../../assets/icon/trash-2.svg";
import calenderNormal from "../../assets/icon/calendar-svgrepo-com.svg";
import calenderGreen from "../../assets/icon/calenderGareen.svg";

const HeaderRow = ["Email", "Type", "Frequency", "Next Scan", "Actions"];

export default function EmailsTable(props: any) {
  const [trashIcon, setTrashIcon] = React.useState<string>(trashNormal);
  const [calenderIcon, setCalenderIcon] = React.useState<string>(calenderNormal);
  const [selectedEmail, setEmail] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openDelete, setDeleteOpen] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>() as any;
  const { emails } = useSelector((state: any) => state.emailScanner);

  const filteredUsers = props?.filterValue.length == 0 ? emails?.data : emails?.data.filter((data: any) =>
    data.mail.toLowerCase().includes(props?.filterValue.toLowerCase())
  );

  React.useEffect(() => {
    dispatch(getEmailScanner(1));
  }, []);

  React.useEffect(() => { }, [props?.filterValue]);

  const handleClickOpen = (email: string) => {
    setEmail(email);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
  };
  const handleDelete = (email: string) => {
    setEmail(email);
    setDeleteOpen(true);
  };
  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "#fffff",
        padding: "20px",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <StyledTableHeader>
          <StyledHeaderRow>
            <StyledTableHeaderCell>Sr.#</StyledTableHeaderCell>
            {HeaderRow.map((row, index) => (
              <StyledTableHeaderCell key={index} align="center">
                {row}
              </StyledTableHeaderCell>
            ))}
          </StyledHeaderRow>
        </StyledTableHeader>
        <TableBody>
          {filteredUsers?.map((row: any, index: number) => (
            <TableRow
              key={row.mail}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <StyledTableCell align="center">{row.mail}</StyledTableCell>
              <StyledTableCell align="center">
                <StyledEmailTypeWrapper>
                  <StyledGoogleIcon src={GoogleIcon}></StyledGoogleIcon>
                  gmail
                </StyledEmailTypeWrapper>
              </StyledTableCell>
              <StyledTableCell align="center">{row.frequency}</StyledTableCell>
              <StyledTableCell align="center">{row.next_scan}</StyledTableCell>
              <StyledTableCell align="center">
                <StyledEditIcon
                  onMouseEnter={() => setCalenderIcon(calenderGreen)}
                  onMouseLeave={() => setCalenderIcon(calenderNormal)}
                  src={calenderIcon}
                  onClick={() => handleClickOpen(row.mail)}
                ></StyledEditIcon>
                <StyledEditIcon
                  onMouseEnter={() => setTrashIcon(trashRed)}
                  onMouseLeave={() => setTrashIcon(trashNormal)}
                  src={trashIcon}
                  onClick={() => handleDelete(row.mail)}
                ></StyledEditIcon>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!filteredUsers && (
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: "20px",
          }}
        >
          <CircularProgress size={80} />
        </Box>
      )}
      <Scheduledialog email={selectedEmail} isOpen={open} handleClose={() => handleClose()} />
      <DeleteAlertDialog email={selectedEmail} isOpen={openDelete} handleClose={() => handleClose()} />
    </TableContainer>
  );
}
