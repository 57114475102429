import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Button from "@mui/material/Button";
import { Grid, InputLabel, TextField } from "@mui/material";
import StepButton from "@mui/material/StepButton";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  StyledMainWrapper,
  StyledStepperWrapper,
  StyledDividerWrapper
} from "./EmailWarmup.style";
import "./EmailWarmup.css";
import dayjs, { Dayjs } from "dayjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import WarmUpSettings from "./warmUpSettings";
import StrategyType from "./StrategyType";
import EmailContent from "./EmailContent";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const steps = ["Warm-up Settings", "Strategy Type", "Email Content", "Review"];
export default function EmailWarmUp(this: any) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  // const [setVolume, setActiveStep] = React.useState(0);
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [from, setFrom] = useState<Date | null>(date);
  // const [to, setValue] = useState<Date | null>(new Date());
  const [age, setAge] = React.useState("");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs("2014-08-18T21:11:54")
  );

  const handleChanges = (newValue: Dayjs | null) => {
    setValue(newValue);
  };

  const useStyles = () => ({
    root: {
      "& .MuiStepIcon-active": { color: "red" },
      "& .MuiStepIcon-completed": { color: "green" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
    }
  });
  const c = useStyles();
  return (
    <StyledMainWrapper>
      <Box>
        <StyledStepperWrapper>
          <h1 className="Heading">New warm-up campaign</h1>
          <StyledDividerWrapper>
            <Box></Box>
          </StyledDividerWrapper>
          <Stepper     sx={{
          '& .MuiStepLabel-root .Mui-completed': {
            color: '#043E74', // circle color (COMPLETED)
          },
          '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
            {
              color: 'grey.500', // Just text label (COMPLETED)
            },
          '& .MuiStepLabel-root .Mui-active': {
            color: '#043E74', // circle color (ACTIVE)
          },
          '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
            {
              color: 'common.white', // Just text label (ACTIVE)
            },
          '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: 'common.white', // circle's number (ACTIVE)
          },
        }} nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton  onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </StyledStepperWrapper>
        {activeStep == 0 && (
          <WarmUpSettings handleNextProp={handleNext}></WarmUpSettings>
        )}
        {activeStep == 1 && (
      <StrategyType handleNextProp={handleNext}></StrategyType>
        )}
         {activeStep == 2 && (
      <EmailContent handleNextProp={handleNext}></EmailContent>
        )}
               
      </Box>
    </StyledMainWrapper>
  );
}


