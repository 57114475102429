import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  StyledEmailsHeading,
  StyledCloseIcon,
  StyledHeadingWrapper,
  StyledScheduleDialogWrapper,
  StyledDateRangeWrapper,
  StyledDateFromWrapper,
  StyledDateToWrapper,
  StyledSecondHeadingWrapper,
  StyledSecondHeadingText,
  StyledScheduleDateWrapper,
  StyledDateAndTimeSection,
  StyledEmailScanBtn,
  StyledDateLabel,
  StyledButtonWrapper,
} from "./EmailScanner.style";
import CloseIcon from "../../assets/icon/closeicon.png";

import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Stack from "@mui/material/Stack";

import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {getEmailScanner, scheduleScanner} from "../../stores/slices/email-scanner-slice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../stores";
import {toast} from "react-toastify";
import { successToast } from "../../Services/toast";

export default function ScheduleDialog(props: any) {
  const { response } = useSelector((state: any) => state.emailScanner);

  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [time, setTime] = React.useState<Dayjs | null>(null);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);
  const [tabValue, setTabValue] = React.useState("one");

  const dispatch = useDispatch<AppDispatch>() as any;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };
  const onSubmit = () => {
    if (!date && !time && !fromDate && !toDate) return false
    let dateFormat = date?.format("YYYY-MM-DD")
    let timeFormat = time?.toDate().toLocaleTimeString('en-GB',{
      hour: '2-digit',
      minute: '2-digit'
    })
    let fromDateFormat = fromDate?.format("YYYY/MM/DD")
    let toDateFormat = toDate?.format("YYYY/MM/DD")
    let payload = {after:fromDateFormat,before:toDateFormat,date:dateFormat,time:timeFormat,mail:props?.email}
    dispatch(scheduleScanner(payload));
  }

  React.useEffect(() => {
    if (response?.success === 'true'){
      props?.handleClose()
      successToast(response?.data)
      dispatch(getEmailScanner(1));
    }
  }, [response]);
  return (
    <div>
      <Dialog
        open={props?.isOpen}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <StyledHeadingWrapper>
            <StyledEmailsHeading>
              Schedule your Email Scrapper
            </StyledEmailsHeading>
            <StyledCloseIcon
              src={CloseIcon}
              onClick={props?.handleClose}
            ></StyledCloseIcon>
          </StyledHeadingWrapper>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ width: "100%", marginBottom: "40px" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="one" label="One Time" />
              <Tab value="two" label="Daily" />
              <Tab value="three" label="Weekly" />
            </Tabs>
          </Box>
          <StyledScheduleDialogWrapper>
            {tabValue === "one" && (
              <>
                <StyledDateRangeWrapper>
                  <StyledDateFromWrapper>
                    <StyledDateLabel>From</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={fromDate}
                        onChange={(newValue) => {
                          setFromDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </StyledDateFromWrapper>
                  <StyledDateToWrapper>
                    <StyledDateLabel>To</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={toDate}
                        onChange={(newValue) => {
                          setToDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </StyledDateToWrapper>
                </StyledDateRangeWrapper>
                <StyledSecondHeadingWrapper>
                  <StyledSecondHeadingText>
                    When you want to schedule?
                  </StyledSecondHeadingText>
                </StyledSecondHeadingWrapper>
                <StyledDateAndTimeSection>
                  <StyledScheduleDateWrapper>
                    <StyledDateLabel>Date</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </StyledScheduleDateWrapper>
                  <StyledScheduleDateWrapper>
                    <StyledDateLabel>Time</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <MobileTimePicker
                            value={time}
                            onChange={(newValue) => {
                              setTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </StyledScheduleDateWrapper>
                </StyledDateAndTimeSection>
              </>
            )}
            {tabValue === "two" &&(
                <StyledScheduleDateWrapper>
                  <StyledDateLabel>Time</StyledDateLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <MobileTimePicker
                          value={time}
                          onChange={(newValue) => {
                            setTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </StyledScheduleDateWrapper>
            )}
            {tabValue === "three" &&(
                <StyledDateAndTimeSection>
                  <StyledScheduleDateWrapper>
                    <StyledDateLabel>Date</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          value={date}
                          onChange={(newValue) => {
                            setDate(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </StyledScheduleDateWrapper>
                  <StyledScheduleDateWrapper>
                    <StyledDateLabel>Time</StyledDateLabel>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <MobileTimePicker
                            value={time}
                            onChange={(newValue) => {
                              setTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </StyledScheduleDateWrapper>
                </StyledDateAndTimeSection>
            )}
          </StyledScheduleDialogWrapper>
        </DialogContent>
        <DialogActions>
          <StyledButtonWrapper>
            <StyledEmailScanBtn onClick={onSubmit}>
              Apply Scan
            </StyledEmailScanBtn>
          </StyledButtonWrapper>
          {/* <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
