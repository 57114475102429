import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

// Function to create axios instance with dynamic token
const createAxiosInstance = (): AxiosInstance => {
  const token = localStorage.getItem("token");
  return axios.create({
    headers: {
      'Authorization': token ? `token ${token}` : '',
    },
  });
};

// Initialize axios instance
export const customAxios: AxiosInstance = createAxiosInstance();

// Intercept requests to update token in headers if available
customAxios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const token = localStorage.getItem("token");
    if (token) {
        if (config.headers && token) {
            config.headers.Authorization = `token ${token}`;
          }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);