import React, { useEffect } from "react";
import { getAccountKey } from "../../stores/slices/login-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import {
  StyledLoaderText,
  StyledLoaderWrapper,
  StyledLogo,
  StyledLogoWrapper,
  StyledTextWrapper,
  StyledWelcomeMessage,
} from "./Loading.style";
import InvoStreakLogo from "../../assets/icon/Favicon.png";
const Loading = () => {
  const queryString = window.location.href;
  let token = new URL(queryString).hash
    .split("&")
    .filter(function (el) {
      if (el.match("access_token") !== null) return true;
    })[0]
    .split("=")[1];
  const dispatch = useDispatch<AppDispatch>() as any;
  const { keyGenerated } = useSelector(
    (state: any) => state.LoginReducer
  );

  useEffect(() => {
    if (token) {
      dispatch(getAccountKey(token));
    }
  }, [token]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      window.location.replace("/Dashboard");
      //  navigate("/Dashboard", { replace: true });
    }
  }, [keyGenerated]);
  return (
    <StyledLoaderWrapper>
      <CircularProgress size={150}></CircularProgress>
      <StyledTextWrapper>
        <StyledLoaderText>Welcome</StyledLoaderText>
        <StyledLogoWrapper>
          <StyledLogo src={InvoStreakLogo}></StyledLogo>
          <StyledWelcomeMessage>InvoStreak is loading...</StyledWelcomeMessage>
        </StyledLogoWrapper>
      </StyledTextWrapper>
    </StyledLoaderWrapper>
  );
};

export default Loading;
