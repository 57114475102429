import React, { useCallback } from "react";
import Sidebar from "../../components/SideBar/Sidebar";

import DashBoardTabs from "./Tabs";
import ProfileHeader from "../../components/ProfileHeader";

import SystemConfigurations from "../SystemConfigurations";
import { useSelector } from "react-redux";

import Insights from "../Insights";

import Preferences from "../Preferences";
import MUISidebar from "../../components/SideBar/index";

// const selectedComponent =()=> {

// }

function Dashboard() {
  const sideBar = useSelector((state: any) => state.SideBarSelectItem);
  const selectedComponent = useCallback(() => {
    if (sideBar.dashBoard) {
      return <DashBoardTabs></DashBoardTabs>;
    } else if (sideBar.configurations) {
      return <SystemConfigurations />;
    } else if (sideBar.preferences) {
      return <Preferences />;
    } else if (sideBar.insights) {
      return <Insights />;
    }
    return <DashBoardTabs />;
  }, [sideBar])();
  return (
    <div>
      {/* <ProfileHeader /> */}
      <div className="container">
        {/* <Sidebar /> */}

        {/* <MUISidebar /> */}

        {/* <div className="main"> */}
        {/* <ProfileHeader /> */}
        {/* <div className="dashboard-tabs"> */}
        {/* <DashBoardTabs /> */}
        {/* <SystemConfigurations /> */}
        {selectedComponent}
      </div>
    </div>
    // </div>
    // </div>
  );
}

export default Dashboard;
