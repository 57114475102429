import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { customAxios } from "../../Services/auth-header";


export const leadsStats = createAsyncThunk(
  "leads/leadsStates",
  async (user, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/leads_stats/`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const leadsStatsGraph = createAsyncThunk(
  "leads/leadsStatesGraph",
  async (obj: any, thunkAPI) => {
    try {
      const url: string = obj.scrapper_name ? `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph/?min_date=${obj.from ? obj.from : ""
        }&max_date=${obj.to ? obj.to : ""}&scrapper_name=${obj.scrapper_name}` : `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph/?min_date=${obj.from ? obj.from : ""
        }&max_date=${obj.to ? obj.to : ""}&db_name=${obj.db_name}`

      const response = await customAxios.get(
        url
      );

      return response.data;
    } catch (err) { }
  }
);

export const getNamesList = createAsyncThunk(
  "leads/getNamesList",
  async (user, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/scrappers_name_list/`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);


export const getScrapperByName = createAsyncThunk(
  "leads/getScrapperByName",
  async (name: string, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph/?scrapper_name=${name}`
      );
      return response.data;
    } catch (err) { }
  }
);


export const getTypedLeads = createAsyncThunk(
  "leads/getTypedLeads",
  async (obj: any, thunkAPI) => {

    try {

      const leadsType = obj.currentLabel == "Valid Leads" ? "valid_leads" : obj.currentLabel == "Invalid Title" ? "invalid_tittle" : obj.currentLabel == "Company URL Not Found" ? "company_url" : "already_exist"
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/leads_stats_graph_detail/?date=${obj.currentDate}&lead_type=${leadsType}&scrapper_name=${obj.scrapperName ? obj.scrapperName : ''}&db_name=${obj.currentDB}`
      );
      return response.data;
    } catch (err) { }
  }
);



const initialState = {
  leads_stats: {},
  loginResponse: false,
  token: "",
  updateUserResponse: false,
  err: "",
  success: false,
  leadsGraphData: {},
  namesList: [],
  currentDB: "mautic4_db",
  showAccounts: true,
  graphLoading: false,
  currentLeads: [],
  currentLeadsLoader: false,

};

const leadsStatsSlice = createSlice({
  name: "leads",
  initialState,
  reducers: {
    loads_stats: (state, { payload }) => {
      state.leads_stats = payload;
    },
    selectDB: (state, { payload }) => {
      switch (payload) {
        case 1:
          state.currentDB = 'mautic4_db'
          break;
        case 2:
          state.currentDB = 'invoblox_db'
          break;
        case 3:
          state.currentDB = "invogames_db";
          break;
        default:
          state.currentDB = "mautic4_db"
          break;

      }
    },
    setShowAccounts: (state, { payload }) => {
      state.showAccounts = payload
    }
  },
  extraReducers: {
    [leadsStats.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [leadsStats.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      leads_stats: action.payload,
      success: true,
    }),
    [leadsStats.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [leadsStatsGraph.pending.type]: (state: any, action: any) => ({
      ...state,
      graphLoading: true
    }),
    [leadsStatsGraph.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      leadsGraphData: action.payload,
      success: true,
      graphLoading: false

    }),
    [leadsStatsGraph.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [getScrapperByName.pending.type]: (state: any, action: any) => ({
      ...state,
      graphLoading: true
    }),
    [getScrapperByName.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      leadsGraphData: action.payload,
      success: true,
      graphLoading: false
    }),
    [getScrapperByName.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [getNamesList.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [getNamesList.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      namesList: action.payload,
      success: true,
    }),
    [getNamesList.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [getTypedLeads.pending.type]: (state: any, action: any) => ({
      ...state,
      currentLeadsLoader: true,
    }),
    [getTypedLeads.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      currentLeads: action.payload,
      success: true,
      currentLeadsLoader: false
    }),
    [getTypedLeads.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),
  },
});

export default leadsStatsSlice.reducer;
export const { selectDB, setShowAccounts } = leadsStatsSlice.actions
