import "./Tabs.css";
import React, { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import { Bar } from "react-chartjs-2";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";

import { useSelector, useDispatch } from "react-redux";

import {
  leadsStatsGraph,
  getTypedLeads,
} from "../../../stores/slices/leads-slice";
import { AppDispatch } from "../../../stores";
import {
  StyledDBIconWrapper,
  StyledDBLabelWrapper,
  StyledLabel,
  StyledLabelScrapper,
  StyledLoaderWrapper,
  StyledSeparator,
  StyledValueLable,
  StyledValueLableText,
  StyledValueLableWrapper,
} from "./Tabs.style";
import InvoGames from "../../../assets/icon/InvoGames Logo fav.svg";
import InvoZoneLogo from "../../../assets/icon/InvoZone Logo fav.svg";
import InvoBlox from "../../../assets/icon/invobox fav.svg";
import CircularProgress from "@mui/material/CircularProgress";
import CurrentLeadsDialog from "../../../components/CurrentLeads/index";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);
let labels: any = [];
let label: any = "";
let leadDate: string = "";
const LeadTab = (props: any) => {
  // const date = new Date();
  // date.setMonth(date.getMonth() - 1);
  // const [from, setFrom] = useState<Date | null>(date);
  // const [to, setValue] = useState<Date | null>(new Date());
  const dispatch = useDispatch<AppDispatch>() as any;
  const [dateError, setDateError] = useState(false);
  const { leadsGraphData, currentDB, graphLoading } = useSelector(
    (state: any) => state.leadsStats
  );
  const chartRef = useRef();
  

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [currentLeadsType, setCurrentLeadsType] = useState({
    currentLabel: "",
    currentDate: "",
  }) as any;

  const open = Boolean(anchorEl);

  const getFirstDataSet = useCallback(() => {
    labels = [];
    leadsGraphData?.list_of_date?.map((date: string) => {
      labels.push(date);
    });

    return leadsGraphData?.complete_leads;
  }, [leadsGraphData])();

  const getSecondDataSet = useCallback(() => {
    return leadsGraphData?.incomplete_leads;
  }, [leadsGraphData])();
  const [dialog, setDialog] = useState(false);
  const hanldeDialogClose = () => {
    setDialog(false);
  };

  let data = {
    labels,

    datasets: [
      {
        label: "Scrapped Leads",

        backgroundColor: "#009734",

        data: getSecondDataSet,
        stacked: true,
        maxBarThickness: 50,

        hoverBackgroundColor: "#009734",
      },

      {
        label: "Company URL Not Found",
        backgroundColor: "#E24626",

        data: leadsGraphData?.company_url_empty_leads,
        stacked: true,
        hoverBackgroundColor: "#E24626",
        maxBarThickness: 50,
      },
      // {
      //   label: "Already Exist",
      //   backgroundColor: "#A6D702",

      //   data: leadsGraphData?.already_exist_leads,
      //   stacked: true,
      //   hoverBackgroundColor: "#A6D702",
      //   maxBarThickness: 50,
      // },
      // {
      //   label: "Invalid Title",
      //   backgroundColor: "#049CF2",

      //   data: leadsGraphData?.invalid_tittle_leads,
      //   stacked: true,
      //   hoverBackgroundColor: "#049CF2",
      //   maxBarThickness: 50,
      // },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let newlabel = context.dataset.label || "";

            label = newlabel;

            leadDate = context.label;

            if (newlabel) {
              newlabel += ": ";
            }
            if (context.parsed.y !== null) {
              newlabel += new Intl.NumberFormat("en-US", {
                // style: "currency",
                // currency: "USD",
              }).format(context.parsed.y);
            }
            return newlabel;
          },
        },
      },
    },
    onClick: (event: any, x: any, yAxes: any) => {
      setCurrentLeadsType({
        ...currentLeadsType,
        currentLabel: label,
        currentDate: leadDate,
        currentDB,
        scrapperName:leadsGraphData?.scrapper_name
      });

      setCurrentLeadsType((currentLeadsType: any) => {
        dispatch(getTypedLeads(currentLeadsType));
        return currentLeadsType;
      });

      // dispatch(getTypedLeads(currentLeadsType));
      setDialog(true);
    },
  };

  const {
    new_leads_count,
    closed_leads_count,
    complete_leads_count,
    failed_leads_count,
    incomplete_leads_count,
    min_date,
    max_date,
    // invalid_title_lead_count,
    // alreay_exist_lead_count,
    company_url_not_found_count,
  } = leadsGraphData || {};
  const totalLeads =
    new_leads_count +
    closed_leads_count +
    complete_leads_count +
    failed_leads_count +
    incomplete_leads_count;
  const currentStats = [
    {
      key: "Total Leads",
      value: new_leads_count,
      date: min_date + " - " + max_date,
      class: "footerleadItemValue-total",
    },

    {
      key: "Valid Leads",
      value: complete_leads_count,
      date: min_date + " - " + max_date,
      to: min_date,
      from: max_date,
      class: "footerleadItemValue-valid",
    },
    // {
    //   key: "Invalid Leads",
    //   value: incomplete_leads_count,
    //   date: min_date + " - " + max_date,
    //   to: min_date,
    //   from: max_date,
    // },
    {
      key: "Company URL not found",
      value: company_url_not_found_count,
      date: min_date + " - " + max_date,
      to: min_date,
      from: max_date,
      class: "footerleadItemValue-url-not-found",
    },
    // {
    //   key: "Already Exist",
    //   value: alreay_exist_lead_count,
    //   date: min_date + " - " + max_date,
    //   to: min_date,
    //   from: max_date,
    //   class: "footerleadItemValue-already-exist",
    // },
    // {
    //   key: "Invalid Title",
    //   value: invalid_title_lead_count,
    //   date: min_date + " - " + max_date,
    //   to: min_date,
    //   from: max_date,
    //   class: "footerleadItemValue-invalid-title",
    // },
    {
      key: "Closed Leads",
      value: closed_leads_count,
      // date: min_date + " - " + max_date,
      // to: min_date,
      // from: max_date,
      class: "footerleadItemValue-valid",
    },
    {
      key: "Leads Failed",
      value: failed_leads_count,
      // date: min_date + " - " + max_date,
      // to: min_date,
      // from: max_date,
      class: "footerleadItemValue-valid",
    },
  ];
  // const staticStats = [
  //   { key: "Closed Leads", value: closed_leads_count },
  //   { key: "Leads Failed", value: failed_leads_count },
  // ];
  const getcurrentDB = () =>
    currentDB === "mautic4_db"
      ? "InvoZone"
      : currentDB === "invoblox_db"
      ? "InvoBlox"
      : "InvoGames";
  const getcurrentDBSrc = () =>
    currentDB === "mautic4_db"
      ? InvoZoneLogo
      : currentDB === "invoblox_db"
      ? InvoBlox
      : InvoGames;

  const getcurrentScrapper = () =>
    leadsGraphData?.scrapper_name
      ? leadsGraphData?.scrapper_name
      : "All Scrappers";
  useEffect(() => {
    const min_date = new Date(`${props.from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${props.to}`).toISOString().slice(0, 10);

    let obj = {
      from: min_date,
      to: max_date,
      db_name: currentDB,
    };
    dispatch(leadsStatsGraph(obj));
  }, []);

  useEffect(() => {
    const min_date = new Date(`${props.from}`).toISOString().slice(0, 10);
    const max_date = new Date(`${props.to}`).toISOString().slice(0, 10);
    //specify the date string and the format it's initially in
    // var mydate = moment('15/11/2000', 'DD/MM/YYYY');

    let obj = {
      from: min_date,
      to: max_date,
      scrapper_name: props.scrapperName,
      db_name: currentDB,
    };

    dispatch(leadsStatsGraph(obj));

    // data.datasets[0].data = leadsGraphData?.complete_leads;
    // data.datasets[1].data = leadsGraphData?.incomplete_leads;
  }, [props.from, props.to, currentDB]);

  const getPercentage = (value: number) => (value * 100) / totalLeads;
  // const handleBarClick = (event: any) => {
  //   console.log(getDatasetAtEvent(chartRef.current, event));
  // };

  useEffect(() => {
    data.datasets[0].data = leadsGraphData?.complete_leads;
    data.datasets[1].data = leadsGraphData?.incomplete_leads;
  }, []);

  useEffect(() => {
    labels = [];
    leadsGraphData?.list_of_date?.map((date: string) => {
      labels.push(date);
    });
  }, [leadsGraphData]);

  return (
    <div className="tabContainer">
      <div
        className="lead-Header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex" }}>
          <StyledValueLable>
            <StyledValueLableWrapper>
              <StyledValueLableText> Leads Date</StyledValueLableText>
            </StyledValueLableWrapper>
          </StyledValueLable>
          <div className="select-Date-leads">
            <label className="dateLable">From:</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={props.from}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  props.setFrom(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      style={{
                        border: "0px",
                        width: "70px",
                      }}
                    />
                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
            </LocalizationProvider>
            <label className="dateLable" style={{ marginLeft: "20px" }}>
              To:
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={props.to}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  props.setValue(newValue);
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      style={{
                        border: "0px",
                        width: "70px",
                      }}
                    />
                    {InputProps?.endAdornment}
                  </Box>
                )}
              />
            </LocalizationProvider>
          </div>

          <div
            style={{
              color: "red",
              paddingTop: "4px",
              paddingLeft: "8px",
              fontSize: "14px",
            }}
          >
            {dateError
              ? "Ending Date should be greater than starting date"
              : ""}
          </div>
        </div>
        <StyledDBLabelWrapper>
          <StyledDBIconWrapper src={InvoBlox}></StyledDBIconWrapper>
          <StyledLabelScrapper>{getcurrentScrapper()}</StyledLabelScrapper>
          <StyledSeparator>|</StyledSeparator>
          <StyledDBIconWrapper src={getcurrentDBSrc()}></StyledDBIconWrapper>

          <StyledLabel>{getcurrentDB()}</StyledLabel>
        </StyledDBLabelWrapper>
      </div>
      <div className="lead-body" style={{cursor:"pointer"}}>
        {graphLoading && (
          <StyledLoaderWrapper>
            {/* <StyledBackdrop open={true}> */}
            <CircularProgress size={200} />
            {/* </StyledBackdrop> */}
          </StyledLoaderWrapper>
        )}

        <Bar
          data={data}
          options={options}
          style={{
            opacity: graphLoading ? 0.5 : 1,
            minHeight: "500px",
            overflow: "scroll",
          }}
          id="myChart"
          ref={chartRef}
          // onClick={handleBarClick}
          
        />
        <CurrentLeadsDialog
          dialog={dialog}
          handleClose={hanldeDialogClose}
          data={currentLeadsType}
        />
      </div>
      <div className="lead-footer">
        <label className="footer-leading">Leads</label>
        <div className="footerlead">
          {currentStats.map((state, index) => (
            <div className="footerleadItem" key={state.key}>
              <div className="footerleadHeader">
                <label className="footerleadItemTitle">{state.key}</label>
                <label className="footerleadItempercent"></label>
              </div>
              <label className={state?.class}>{state.value}</label>
              <div
                style={{
                  color: "#C0C0C0",

                  display: "flex",
                  fontSize: "12px",
                  minHeight: "20px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                 
                }}
              >
                {state.date ? "Date: " + state.date : ""}
              </div>
            </div>
          ))}
        </div>
        <br style={{ marginTop: "40px" }} />
        {/* <div className="footerlead">
          {staticStats.map((state, index) => (
            <div className="footerleadItem" key={state.key}>
              <div className="footerleadHeader">
                <label className="footerleadItemTitle">{state.key}</label>
                <label className="footerleadItempercent"></label>
              </div>
              <label className="footerleadItemValue">{state.value}</label>
            </div>
          ))}
        </div> */}
      </div>
      <div></div>
    </div>
  );
};

export default LeadTab;
