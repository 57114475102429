import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import {
  StyledBackIcon,
  StyledDownloadWrapper,
  StyledIconWrapper,
  StyledLoaderWrapper,
  StyledTextWrapper,
  StyledTitleWrapper,
  StyledHeaderWrapper,
  StyledDownloadBtn,
  StyledDownloadIcon,
  StyledNoRecordWrapper,
  StyledTableCell,
} from "./CurrentLeads.style";
import ArrowBack from "../../assets/icon/arrow-back.png";
// import CloseIcon from "../../assets/icon/close.png";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CSVLink } from "react-csv";
import DownloadIcon from "../../assets/icon/download.png";

import ValidLeadsDetails from "./ValidLeadsDetails"

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const HeaderRow = [
  "Title",
  "Lead URL",
  "Company URL",
  "Category",

  "City",
  "Company",
  "Lead Date",
  "Platform",
  "Posted Date",
  "Region",
  "Job Fetch Time",
];

// const typesArray = [
//   "id",
//   "title",
//   "category",
//   "city",
//   "company",
//   "lead_date",
//   "platform",
//   "lead_url",
//   "company_url",
//   "posted_date",
//   "region",
//   "job_fetch_timestamp",
// ];

export default function AlertDialogSlide(props: any) {
  const { currentLeads, currentLeadsLoader } = useSelector(
    (state: any) => state.leadsStats
  );

  return (
    <div>
      <Dialog
        open={props.dialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth={"lg"}
        sx={{
          backgroundColor: "none",
          borderRadius: "16px",
          minHeight: "500px",
        }}
        PaperProps={{
          style: { borderRadius: "16px", minHeight: "400px", minWidth: "60%" },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "#F8F8F8",
            borderRadius: "16px 16px 0px 0px",
            display: "flex",
            justifyContent: "space-between",
            minWidth: "500px",
            padding: "10px",
          }}
        >
          <StyledTitleWrapper>
            <StyledBackIcon
              src={ArrowBack}
              onClick={props.handleClose}
            ></StyledBackIcon>
            <StyledTextWrapper>
              {`${props?.data?.currentLabel}` +
                ` | ` +
                `${props?.data?.currentDate}`}
            </StyledTextWrapper>
          </StyledTitleWrapper>
          <StyledHeaderWrapper>
            <StyledDownloadWrapper>
              {!currentLeadsLoader && currentLeads?.leads?.length !== 0 && (
                <CSVLink
                  filename={
                    `${props?.data?.currentLabel}` +
                    "-" +
                    `${props?.data?.currentDate}`
                  }
                  data={
                    currentLeads?.leads ? currentLeads?.leads : "[{one},{two}]"
                  }
                  className="btn btn-primary"
                >
                  <StyledDownloadBtn variant="contained">
                    Download CSV
                    <StyledDownloadIcon src={DownloadIcon}></StyledDownloadIcon>
                  </StyledDownloadBtn>
                </CSVLink>
              )}
            </StyledDownloadWrapper>
            <StyledIconWrapper onClick={props.handleClose}>
              {/* <StyledCloseIcon
              src={CloseIcon}
              onClick={props.handleClose}
            ></StyledCloseIcon> */}

              <CloseIcon></CloseIcon>
            </StyledIconWrapper>
          </StyledHeaderWrapper>
        </DialogTitle>
        <DialogContent sx={{ borderRadius: "16px", px: "32px" }}>
          <div id="alert-dialog-slide-description">
            {/* {leads && leads?.map((lead: any) => lead.company)} */}
            {/* <table> */}

            {!currentLeadsLoader && currentLeads?.leads?.length !== 0 && props.data.currentLabel !=="Valid Leads"  ? (
              <TableContainer sx={{ maxHeight: "500px", marginTop: "16px" }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        fontWeight: "bolder",
                        border: "none",
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          minWidth: "100px",
                          border: "none",
                        }}
                      >
                        Id
                      </TableCell>
                      {HeaderRow.map((heading: string, index: number) => (
                        <TableCell
                          sx={{
                            fontWeight: "bold",
                            minWidth: "200px",
                            border: "none",
                          }}
                          align={index > 4 ? "left" : "left"}
                          key={index}
                        >
                          {heading}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentLeads?.leads?.map((item: any, index: number) => (
                      <TableRow
                        key={index}
                        sx={{
                          border: 0,

                          "&:td, &:th": {
                            border: 0,
                            fontSize: "12px",
                          },
                          fontSize: "12px",
                          "*": {
                            borderBottom: 0,
                            fontSize: "12px",
                            border: 0,
                          },

                          background: index % 2 === 0 ? "#FFFFF;" : "#F8F8F8",
                          padding: "8px",

                          borderRadius: "8px",
                        }}
                      >
                        <StyledTableCell>{item.id}</StyledTableCell>

                        {/* {typesArray.map((type) => (
                          <>
                            <StyledTableCell>{item[type]}</StyledTableCell>
                          </>
                        ))} */}

                        <StyledTableCell align="left">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">Title</Typography>
                                <Typography component="p">
                                  {item.title}
                                </Typography>
                                <a target={"_blank"} href={item.lead_url}>
                                  <Typography
                                    noWrap
                                    sx={{ width: "200px", fontSize: "12px" }}
                                  >
                                    {item.lead_url ? item.lead_url : "-"}
                                  </Typography>
                                </a>
                              </React.Fragment>
                            }
                          >
                            {/* <Button>HTML</Button> */}
                            <Typography
                              noWrap
                              sx={{ width: "250px", fontSize: "12px" }}
                            >
                               {item.title ? item.title : "-"}
                            </Typography>
                          </HtmlTooltip>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <a target={"_blank"} href={item.lead_url}>
                            <Typography
                              noWrap
                              sx={{ width: "300px", fontSize: "12px" }}
                            >
                              {item.lead_url ? item.lead_url : "-"}
                            </Typography>
                          </a>
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <a target={"_blank"} href={item.company_url}>
                            <Typography
                              noWrap
                              sx={{ width: "200px", fontSize: "12px" }}
                            >
                              {item.company_url ? item.company_url : "-"}
                            </Typography>
                          </a>
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.category ? item.category : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.city ? item.city : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.company ? item.company : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.lead_date ? item.lead_date : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.platform ? item.platform : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.posted_date ? item.posted_date : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.region ? item.region : "-"}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {item.job_fetch_timestamp
                            ? item.job_fetch_timestamp
                            : "-"}
                        </StyledTableCell>

                        {/* <TableCell align="center">{item.validated}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ): !currentLeadsLoader && currentLeads?.leads?.length !== 0 && props.data.currentLabel ==="Valid Leads" ? (<ValidLeadsDetails validLeads={currentLeads?.leads}/>) : !currentLeadsLoader && currentLeads?.leads?.length === 0 ? (
              <StyledNoRecordWrapper>No record found</StyledNoRecordWrapper>
            ) : (
              // ))
              <StyledLoaderWrapper>
                <CircularProgress />
              </StyledLoaderWrapper>
            )}
            {/* </table> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
