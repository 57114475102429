import * as React from "react";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { updatePreferences } from "../../stores/slices/preferences-slice";
import { useEffect } from "react";
import { AppDispatch } from "../../stores";
import { useDebounce } from "usehooks-ts";
import Button from "@mui/material/Button";
import { StyledInput } from "../../pages/Preferences/Preferences.style";
import {
  StyledButton,
  StyledIcon,
  StyledRemoveIcon,
  StyledButtonsWrapper,
  StyledBtnInnerWrapper,
  StyledWrapper,
} from "./DebounceKeys.style";

export default function Tags(props: any) {
  const { debounce_keys } = props?.preferences || {};
  // const { preferences } = useSelector((state: any) => state.preferences);
  const [keys, setKeys] = useState(
    JSON.parse(props.preferences.debounce_keys ? debounce_keys : "[]")
  );

  const debouncedValue = useDebounce<string>(keys, 500);

  const [counter, setCounter] = useState(keys?.length);

  const dispatch = useDispatch<AppDispatch>() as any;

  const handleDelete = (value: any) => {
    setKeys((keys: any) => keys?.filter((key: any) => key !== value));
    setCounter(counter - 1);

    let obj = {
      id: props?.preferences?.id,
      email: props?.preferences?.email,
      password: props?.preferences?.password,

      debounce_keys: JSON.stringify(keys),
    };

    dispatch(updatePreferences(obj));
  };

  const handleClick = (value: string, index: number) => {
    setCounter(counter + 1);
  };

  const handleOnChange = (e: any, index: number) => {
    let newKeys = [...keys];

    newKeys[index] = e.target.value;
    setKeys(newKeys);
  };
  useEffect(() => setCounter(keys.length), [keys.length]);
  useEffect(() => {
    setKeys(
      JSON.parse(props.preferences?.debounce_keys ? debounce_keys : "[]")
    );
    setCounter(keys?.length);
  }, [debounce_keys]);
  useEffect(() => {
    let obj = {
      id: props?.preferences?.id,
      email: props.preferences.email,
      password: props.preferences.password,

      debounce_keys: JSON.stringify(keys),
    };

    if (obj.id !== undefined) {
      dispatch(updatePreferences(obj));
    }
  }, [debouncedValue]);

  return (
    <div>
      {counter === 0 && (
        <Button
          variant="text"
          sx={{ color: "#043E74", fontSize: "12px", fontWeight: "bold" }}
          onClick={() => handleClick("1", 1)}
        >
          Add new keys
        </Button>
      )}

      {Array.from(Array(counter)).map((c, index) => (
        <StyledWrapper>
          <StyledInput
            onChange={(event: any) => handleOnChange(event, index)}
            value={keys[index] ? keys[index] : ""}
            style={{ margin: "10px" }}
            key={index}
          />

          <StyledButtonsWrapper>
            <StyledBtnInnerWrapper>
              <StyledButton onClick={() => handleClick(keys[index], index)}>
                <StyledIcon></StyledIcon>
              </StyledButton>
              <StyledButton onClick={() => handleDelete(keys[index])}>
                <StyledRemoveIcon></StyledRemoveIcon>
              </StyledButton>
            </StyledBtnInnerWrapper>
          </StyledButtonsWrapper>
        </StyledWrapper>
      ))}
    </div>
  );
}
