import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Outlet, useNavigate, useLocation } from "react-router";
import ProfileHeader from "../ProfileHeader";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ICON } from "../../assets/icon";
import { BsFillPieChartFill } from "react-icons/bs";

import TuneIcon from "@mui/icons-material/Tune";
import PreferenesIcon from "../../assets/icon/preferences.png";
import GmailScrapperIcon from "../../assets/icon/gmailscrappertab.png";
import {
  StyledIcon,
  StyledIconLeft,
  StyledIconRight,
  StyledLogoHeading,
  StyledLogoWrapper,
  StyledSidebarlogo,
} from "./Sidebar.style";
import Sidebarlogo from "../../assets/icon/Sidebarlogo.svg";
import DashboardTab from "../../assets/icon/dashboardtab.svg";
import EmailscrapperTab from "../../assets/icon/emailscrappertab.svg";
import PreferencesTab from "../../assets/icon/preferencestab.svg";
import Systemconfigurationstab from "../../assets/icon/systemconfigurations.svg";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const routes = [
    {
      title: "Dashboard",
      route: "/Dashboard",
    },
    {
      title: "Configurations",
      route: "/System-configurations",
    },
    {
      title: "Preferences",
      route: "/preferences",
    },

    {
      title: "Email Scanner",
      route: "/email-scanner",
    },
    {
      title: "Email Warmup",
      route: "/email-warmup",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* <CssBaseline /> */}
      <AppBar
        position="fixed"
        open={open}
        sx={{ backgroundColor: "white", boxShadow: 0 }}
      ></AppBar>

      <Drawer
        variant="permanent"
        open={open}
        sx={{
          backgroundColor: "#043E74",
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          {open && (
            <StyledLogoWrapper>
              {/* <div className="logotext" style={{ paddingLeft: "20px" }}> */}
              <div style={{ backgroundColor: "#013463" }}>
                {/* small and big change using menucollapse state */}
                {/* <img src={ICON.Logo} alt="Logo" />{" "} */}
              </div>
              {/* </div> */}

              <StyledSidebarlogo src={Sidebarlogo}></StyledSidebarlogo>
              <StyledLogoHeading>Invostreak</StyledLogoHeading>
            </StyledLogoWrapper>
          )}

          <IconButton onClick={() => setOpen(!open)} sx={{ color: "#DDE2FF" }}>
            {!open ? <StyledIconRight /> : <StyledIconLeft />}
          </IconButton>
        </DrawerHeader>
        {/* <Divider /> */}
        <List
          sx={{
            color: "#DDE2FF",
            diaplay: "flex",
            flexDirection: "column",
            marginTop: "12%",
          }}
        >
          {routes.map((tab, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block" }}
              onClick={() => navigate(tab.route)}
              selected={tab.route === pathname}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  color: tab.route === pathname ? "#DDE2FF" : "#A4A6B3",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      tab.route === pathname && !open ? "#DDE2FF" : "#A4A6B3",
                  }}
                >
                  {index === 0 ? (
                    <StyledIcon
                      src={DashboardTab}
                      style={{
                        color:
                          tab.route === pathname && !open
                            ? "#DDE2FF"
                            : "#A4A6B3",
                      }}
                    ></StyledIcon>
                  ) : index === 1 ? (
                    <StyledIcon src={Systemconfigurationstab}></StyledIcon>
                  ) : index === 2 ? (
                    <StyledIcon src={PreferencesTab}></StyledIcon>
                  ) : (
                    <StyledIcon src={EmailscrapperTab}></StyledIcon>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={tab.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {tab.route === pathname && open && (
                  <FiberManualRecordIcon sx={{ fontSize: "12px" }} />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <ProfileHeader />
        {/* <DrawerHeader /> */}
        {/* <Typography paragraph> */}
        <Outlet />
        {/* </Typography> */}
      </Box>
    </Box>
  );
}
