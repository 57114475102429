import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";

export const StyledHeading = styled.p`
// font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
/* identical to box height */

letter-spacing: 0.4px;
text-transform: capitalize;

/* grayscale / black */


`;





export const StyledFormLabel = styled(FormControlLabel)`
font-family: Poppins;
font-style: normal;
font-weight: 400;
font-size: 14px !important;
line-height: 21px;
/* identical to box height */


color: #333333;
`;

export const StyledButton = styled.button`
  height: 100%;
  max-height: 45px;
  padding: 8px 16px;
  background: #043E74;
  border-radius: 2px;
  border-color: #043E74;
  &:hover {
    background: #043E74;
    border-color: #043E74;
    opacity: 0.8;
  }

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  border-radius: 20px;
  margin-top:8px;
  color: #ffff;
  border: 1px solid #043E74; 
  margin-left:24px
`;

export const StyledSearchInput = styled.input`
box-sizing: border-box;
background: #f9f9f9;
background: #f9f9f9;
border: 1px solid #ededed;
border-radius: 6px;
width: 322px;
height: 40px;
border: "none";
outline: rgba(121, 121, 121, 0.16);
`;


export const StyledSearchWrapper = styled.div`
  float: 'right'
  margin-bottom: 8px;
  padding-right: 8px;
  /* Merge provided styles with default styles */

`;


export const StyledPaginationWrapper = styled.div`
display:flex;

`;
export const StyledPerPageWrapper = styled.div`

`;

