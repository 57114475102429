import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GmailIcon from "../../assets/icon/gmail.png";
import {
    StyledEmailsWrapper,
    StyledEmailIcon,
    StyledEmailIconWrapper,
    StyledEmailsHeading,
    StyledEmailTypeText,
    StyledCloseIcon,
    StyledHeadingWrapper,
} from "./EmailScanner.style";
import ExchangeIcon from "../../assets/icon/exchange.png";
import SMPTIcon from "../../assets/icon/smtp.png";
import OutlookIcon from "../../assets/icon/outlook-svgrepo-com.svg";
import CloseIcon from "../../assets/icon/closeicon.png";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../stores";
import {addEmail, getEmailScanner, reset} from "../../stores/slices/email-scanner-slice";
import {useEffect, useRef} from "react";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EmailSelectDialog(props: any) {
    const dispatch = useDispatch<AppDispatch>() as any;
    const {success, error, errorMSG} = useSelector((state: any) => state.emailScanner);

    const toastId: any = useRef(null)

    const handleSelectEmail = () => {
        toastId.current = toast.loading('Please wait!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
        let sting = (Math.random() + 1).toString(36).substring(5);
        let url = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=349240869808-up90e05d178j4avb0qgql8r8lhtnffo7.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fgmail-scanner.invo.zone&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.send&state=${sting}&access_type=offline`
        window.open(url, '_blank', 'noreferrer');
        dispatch(addEmail(sting))
    };
    useEffect(() => {
        if (success) {
            props?.handleClose();
            toast.update(toastId.current, {
                render: "Success!",
                type: "success",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(reset())
            dispatch(getEmailScanner(1));
        } else if (error) {
            toast.update(toastId.current, {
                render: errorMSG?.response?.data?.error_message,
                type: "error",
                isLoading: false,
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            dispatch(getEmailScanner(1));
        }
    }, [success, error]);
    return (
        <div>
            <ToastContainer/>
            <Dialog
                open={props?.isOpen}
                onClose={props?.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={"sm"}
            >
                <DialogTitle id="alert-dialog-title">
                    <StyledHeadingWrapper>
                        <StyledEmailsHeading>Select email account type</StyledEmailsHeading>
                        <StyledCloseIcon
                            src={CloseIcon}
                            onClick={props?.handleClose}
                        ></StyledCloseIcon>
                    </StyledHeadingWrapper>
                </DialogTitle>
                <DialogContent sx={{paddingBottom: "0px"}}>
                    <StyledEmailsWrapper>
                        <StyledEmailIconWrapper>
                            <StyledEmailIcon
                                src={GmailIcon}
                                onClick={handleSelectEmail}
                            ></StyledEmailIcon>
                            <StyledEmailTypeText>Gmail</StyledEmailTypeText>
                        </StyledEmailIconWrapper>
                        <StyledEmailIconWrapper style={{backgroundColor: '#F0F0F0'}}>
                            <StyledEmailIcon
                                src={OutlookIcon}
                            ></StyledEmailIcon>
                            <StyledEmailTypeText>Outlook</StyledEmailTypeText>
                        </StyledEmailIconWrapper>
                        <StyledEmailIconWrapper style={{backgroundColor: '#F0F0F0'}}>
                            <StyledEmailIcon
                                src={ExchangeIcon}
                            ></StyledEmailIcon>
                            <StyledEmailTypeText>Exchange</StyledEmailTypeText>
                        </StyledEmailIconWrapper>
                        <StyledEmailIconWrapper style={{backgroundColor: '#F0F0F0'}}>
                            <StyledEmailIcon
                                src={SMPTIcon}
                            ></StyledEmailIcon>
                            <StyledEmailTypeText>SMTP</StyledEmailTypeText>
                        </StyledEmailIconWrapper>
                    </StyledEmailsWrapper>
                </DialogContent>
            </Dialog>
        </div>
    );
}
