import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TuneIcon from "@mui/icons-material/Tune";
import Slider from "@mui/material/Slider";

import Box from "@mui/material/Box";

import { updateConfigStatus } from "../../stores/slices/preferences-slice";

import { useDebounce } from "usehooks-ts";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import { StyledInput } from "./Controls.style";
// import Typography from "@mui/material/Typography";

const marks = [
  {
    value: 0,
    label: "0(mins)",
  },

  {
    value: 5000,
    label: "5000(mins)",
  },
];
const LeadsMarks = [
  {
    value: 0,
    label: "0(Leads)",
  },

  {
    value: 5000,
    label: "5000(Leads)",
  },
];

// function valueLabelFormat(value: number) {
//   let scaledValue = value;
//   return `${Math.ceil(scaledValue)} ${"mins"}`;
// }
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

// interface scrapperDataType {
//   id: number;
//   delay: number;
//   older_lead_threshold: number;
//   path: string;
//   search: string;
//   job_title: string;
//   search_keyword: string;
//   status: boolean;
// }

export default function CustomizedDialogs(props: any) {
  const [open, setOpen] = React.useState(false);
  const {
    id,
    delay,
    job_title,
    name,
    older_lead_threshold,
    path,
    search_keyword,
    status,
  } = props.scrapperData;
  const [makeApiCall, setMakeApiCall] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(delay);
  const debouncedValue = useDebounce<string>(`${value}`, 500);

  const [oldLeads, setOldLeads] = React.useState<number>(older_lead_threshold);

  const debouncedOldLeadsValue = useDebounce<string>(`${oldLeads}`, 500);
  const [forAll, setForAll] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>() as any;
  const [showBtn, setShowBtn] = React.useState(false);
  const [showBtnLeads, setShowBtnLeads] = React.useState(false);
  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setValue(newValue);
      setMakeApiCall(true);
    }
  };
  const handleOldLeadsChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setOldLeads(newValue);
      setMakeApiCall(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const setRange = (value: any) => {
    setValue(value.target.value);
  };
  const setRangeLeads = (value: any) => {
    setOldLeads(value.target.value);
  };

  const handleSetToAll = () => {
    setForAll(true);

    let obj = {
      id,
      delay: value,
      search_keyword,
      job_title,
      name,
      path,
      older_lead_threshold: oldLeads,
      status,
      delay_set_to_all: true,
      keywords_set_to_all: false,
    };

    dispatch(updateConfigStatus(obj));
  };

  useEffect(() => {
    if (open) {
      let obj = {
        id,
        delay: value,
        search_keyword,
        job_title,
        name,
        path,
        older_lead_threshold: oldLeads,
        status,
        delay_set_to_all: false,
        keywords_set_to_all: false,
      };

      dispatch(updateConfigStatus(obj));
    }

    setMakeApiCall(false);
  }, [debouncedValue, debouncedOldLeadsValue]);

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="upload picture"
        onClick={handleClickOpen}
        size="small"
        component="label"
      >
        <TuneIcon sx={{ fontSize: "16px", color: "#043E74" }} />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: "bold" }}>
          {"Scrapper Controls"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "40px", minWidth: "400px" }}>
            {/* <small>Time delay for a scrapper after one successful run.</small> */}
            <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
              Time Delay
            </Typography>
            <Typography
              display="block"
              gutterBottom
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Time delay for a scrapper after one successful run.
            </Typography>
            <Slider
              value={value}
              min={0}
              step={1}
              max={5000}
              // scale={calculateValue}
              // getAriaValueText={valueLabelFormat}
              // valueLabelFormat={valueLabelFormat}
              onChange={handleChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
              marks={marks}
              sx={{ color: "#043E74" }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {showBtn && (
                <StyledInput
                  id="outlined-basic"
                  type="number"
                  onChange={($event: any) => setRange($event)}
                  min="0"
                  max="5000"
                />
              )}
            </Box>

            <Typography
              sx={{ fontSize: "16px", fontWeight: "bold", mt: "20px" }}
            >
              Leads Threshold
            </Typography>
            <Typography
              display="block"
              gutterBottom
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Number of older leads to be scrapped by this scrapper.
            </Typography>
            <Slider
              value={oldLeads}
              min={0}
              step={1}
              max={5000}
              onChange={handleOldLeadsChange}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
              marks={LeadsMarks}
              sx={{ color: "#043E74" }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {showBtnLeads && (
                <StyledInput
                  type="number"
                  onChange={($event: any) => setRangeLeads($event)}
                  min="0"
                  max="5000"
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end", mt: "16px" }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => handleSetToAll()}
              sx={{
                backgroundColor: "#043E74",
                borderRadius: "6px",
                color: "#ffff",
              }}
            >
              Set for All
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#043E74" }} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
