import React from "react";
import Sidebar from "../../components/SideBar/Sidebar";
import "./index.css";
import ProfileHeader from "../../components/ProfileHeader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Pie } from "react-chartjs-2";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [
        1030, 2090, 3000, 4000, 2000, 1100, 1030, 2090, 3000, 4000, 2000, 1100,
      ],
      backgroundColor: [
        "#082E51",
        "#043E74",
        "#DFE0EB",
        "#DFE0EB",
        "#5F9ED9",
        "#007CEF",
      ],
    },
  ],
};

ChartJS.register(ArcElement, Tooltip, Legend);

export const Paidata = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#082E51",
        "#043E74",
        "#DFE0EB",
        "#DFE0EB",
        "#5F9ED9",
        "#007CEF",
      ],
      borderColor: [
        " #e5e5e5",
        " #e5e5e5",
        " #e5e5e5",
        " #e5e5e5",
        " #e5e5e5",
        " #e5e5e5",
      ],
      borderWidth: 1,
    },
  ],
};

function createData(name: string, calories: number, fat: number) {
  return { name, calories, fat };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0),
  createData("Ice cream sandwich", 237, 9.0),
];
const rowws = [
  createData("Frozen yoghurt", 159, 6.0),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Eclair", 262, 16.0),
  createData("Cupcake", 305, 3.7),
];
function Dashboard() {
  return (
    <>
      {" "}
      <div className="insigts-body">
        <div className="insigts-div">
          <TableContainer component={Paper} sx={{ background: "#e5e5e5" }}>
            <Table sx={{ minWidth: 400 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Dessert (100g serving)</TableCell>
                  <TableCell align="right">Calories</TableCell>
                  <TableCell align="right">Fat&nbsp;(g)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowws.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="insigts-div">
          <div>
            <TableContainer component={Paper} sx={{ background: "#e5e5e5" }}>
              <Table sx={{ minWidth: 400 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ border: 0 }}>
                      Dessert (100g serving)
                    </TableCell>
                    <TableCell sx={{ border: 0 }} align="right">
                      Calories
                    </TableCell>
                    <TableCell sx={{ border: 0 }} align="right">
                      Fat&nbsp;(g)
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell sx={{ border: 0 }} component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="right">
                        {row.calories}
                      </TableCell>
                      <TableCell sx={{ border: 0 }} align="right">
                        {row.fat}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="insigts-body">
        <div className="insigts-div">
          <label className="graphHeading">Lead Drivers</label>
          <label className="graphText">
            Track conversion success across campaign channels
          </label>
          <div style={{ padding: "60px" }}>
            <Pie data={Paidata} />
          </div>
        </div>
        <div className="insigts-div">
          <label className="graphHeading">Recent Campaigns Engagements</label>
          <label className="graphText">
            Track Campaign performance over the last 14 days
          </label>
          <div style={{ paddingTop: "60px" }}>
            <Bar
              options={options}
              data={data}
              style={{ height: 300, width: 500 }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
