import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import { useState, useRef } from "react";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import { updateConfigStatus, updateCommonKeywords } from "../../stores/slices/configurations-slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../stores";
import Typography from "@mui/material/Typography";

import { AutocompleteGetTagProps } from "@mui/base/AutocompleteUnstyled";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useCallback } from "react";
import { customAxios } from "../../Services/auth-header";
// import Button from "@mui/material/Button";
import SettingsIcon from '@mui/icons-material/Settings';

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const CommonKeywords = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [newKeyword, setNewKeyword] = useState("") as any;
  const [newJobTitle, setNewJobTitle] = useState("") as any;
  const [allKeywords, setAllKeywords] = useState([])
  const [allTitles, setAllTitles] = useState([])

  const dispatch = useDispatch<AppDispatch>() as any;

  const [keywords, setKeywords] = useState(
    props?.search_keyword
  );
  const [jobTitles, setJobTitles] = useState(
    props?.job_title
  );
React.useEffect(()=>{
  setKeywords(props?.commonData?.search_keyword);
  setJobTitles(props?.commonData?.job_title);
},[props.commonData.search_keyword, props.commonData.job_title])

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function fetchData(query: any) {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}stats/scrapper_keywords?query=${query}`;
      const response = await customAxios.get(url);
      return response.data.data;
    } catch (err) {
      console.error(err);
      // Handle errors appropriately in your actual code
      return []
    }
  }

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    async function loadInitialData() {
      const fetchedKeywords = await fetchData('new_keywords');
      const titles = await fetchData('title_keywords');

      setAllTitles(titles);
      setAllKeywords(fetchedKeywords);
    }
    
    loadInitialData();
  }, []);

  const onInputChange = (event: any, value: any) => {
    if (event.key === "Enter") {
      if (keywords === null) setKeywords([]);
      let kw = [...keywords, newKeyword]
      setKeywords((keywords: any) => kw);

      let obj = {
        id: props.commonData.id,
        search_keyword: JSON.stringify(kw),
      };

      dispatch(updateCommonKeywords(obj));
    } else {
      setNewKeyword(value);
    }
  };

  const onInputJobTitlesChange = (event: any, value: any) => {
    if (event.key === "Enter") {
      if (jobTitles === null) setJobTitles([]);
      let ttl = [...jobTitles, newJobTitle]
      setJobTitles((jobTitles: any) => ttl);

      let obj = {
        id: props.commonData.id,
        job_title: JSON.stringify(ttl),
      };

      dispatch(updateCommonKeywords(obj));
    } else {
      setNewJobTitle(value);
    }
  };
  const handleRemoveItem = (value: any) => {
    // console.log("jlfksdjfldffjdslf", value);
  };
  const handleDelete = (value: any) => {
    
    const keywordsUpdated = keywords?.filter((keyword: any) => keyword !== value)
    setKeywords(keywordsUpdated);

    let obj = {
      id: props.commonData.id,
      search_keyword: JSON.stringify(keywordsUpdated),
    };

    dispatch(updateCommonKeywords(obj));
  };

  const handleJobTitlesDelete = (value: any) => {
    setJobTitles((jobTitles: any) =>
      jobTitles?.filter((jobTitle: any) => jobTitle !== value)
    );
    setTimeout(() => {
      let obj = {
        id: props.commonData.id,
        job_title: JSON.stringify(jobTitles),
      };

      dispatch(updateCommonKeywords(obj));
    }, 500);
  };

  const handleTitlesChipClick = ( value: any) => {
  
    if (jobTitles === null || jobTitles === "null") {
      setJobTitles([]);
    }
    
    let ttl = [...jobTitles, value];
    setJobTitles(ttl);
  
    let obj = {
      id: props.commonData.id,
      job_title: JSON.stringify(ttl),
    };

    dispatch(updateCommonKeywords(obj));
  };

  const handleKeywordChipClick = ( value: any) => {
  
    if (keywords === null || keywords === "null") {
      setJobTitles([]);
    }
    
    let ttl = [...keywords, value];
    setJobTitles(ttl);
  
    let obj = {
      id: props.commonData.id,
      search_keyword: JSON.stringify(ttl),
    };

    dispatch(updateCommonKeywords(obj));
  };

  const getValues = useCallback(() => {
    if (keywords?.length) {
      return [...keywords?.map((item: any) => item)];
    }
    return props?.search_keyword === "null" ||
      props?.search_keyword == null
      ? []
      : [...keywords?.map((item: any) => item)];
  }, [keywords]);
  const getJobTitlesValues = useCallback(() => {
    if (jobTitles?.length) {
      return [...jobTitles?.map((item: any) => item)];
    }
    return props?.job_title == "null" ||
      props?.job_title == null
      ? []
      : [...jobTitles?.map((item: any) => item)];
  }, [jobTitles]);

  return (
    <div>
      <IconButton
        color="primary"
        aria-label="upload picture"
        onClick={handleClickOpen}
        size="small"
        component="label"
      >
        <SettingsIcon
          sx={{ fontSize: "20px", color: "#043E74" }}
          fontSize="small"
        />
        <span style={{ marginLeft: "8px", color: "#043E74" }}>Keyword Settings</span>
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              padding: "40px",
            },
          },
        }}
      >
        <DialogContent sx={{ overflow: "scroll" }}>
          <Typography
            gutterBottom
            sx={{ marginTop: "16px", fontSize: "16px", fontWeight: "bold" }}
          >
            Search Keywords
          </Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              disableClearable
              options={allKeywords}
              freeSolo
              value={getValues()}
              limitTags={10}
              onInputChange={onInputChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    // onClick={(e: any, value: any) => handleRemoveItem(value)}
                    onDelete={() => handleDelete(option)}
                    // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
                    size="small"
                  />
                ))
              }
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Add Search Keywords" />
              )}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={selected ? undefined : () => handleKeywordChipClick(option)}  // Conditionally assigning the onClick handler
                >
                  {option}
                </li>
              )}
              ChipProps={{
                clickable: false,
                onDelete: handleRemoveItem,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
          <Typography
            gutterBottom
            sx={{ marginTop: "16px", fontSize: "16px", fontWeight: "bold" }}
          >
            Job Titles
          </Typography>
          <Box sx={{ py: "10px" }}>
            <Autocomplete
              multiple
              id="tags-filled"
              disableClearable
              filterSelectedOptions
              options={allTitles}
              freeSolo
              value={getJobTitlesValues()}
              limitTags={10}
              onInputChange={onInputJobTitlesChange}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                    onDelete={() => handleJobTitlesDelete(option)}
                    size="small"
                  />
                ))
              }
              renderInput={(params: any) => (
                <TextField {...params} placeholder="Add Job Titles" />
              )}
              renderOption={(props, option, { selected }) => (
                <li
                  {...props}
                  onClick={selected ? undefined : () => handleTitlesChipClick(option)}  // Conditionally assigning the onClick handler
                >
                  {option}
                </li>
              )}
              ChipProps={{
                clickable: false,
                variant: "outlined",
                color: "primary",
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color: "#043E74" }} autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CommonKeywords;
