import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAxios } from "../../Services/auth-header";


export const getScrappersConfig = createAsyncThunk(
  "config/getScrappersConfig",
  async ({ page, query }: { page: number; query?: string }, { dispatch }) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}stats/scrapper_config/?page=${page}`;
      if (query) {
        url += `&name=${encodeURIComponent(query)}`;
      }
      const response = await customAxios.get(url);
      // Optional: Dispatch any relevant actions or just return the data
      return response.data;
    } catch (err) {
      console.error(err);
      // Handle errors appropriately in your actual code
      throw err;
    }
  }
);

export const getCommonKeywords = createAsyncThunk(
  "config/getCommonKeywords",
  async ({ page }: { page: number }, { dispatch }) => {
    try {
      let url = `${process.env.REACT_APP_BASE_URL}stats/scrapper_common_info/?name=commons`;
      const response = await customAxios.get(url);
      return response.data.data;
    } catch (err) {
      console.error(err);
      // Handle errors appropriately in your actual code
      throw err;
    }
  }
);


export const updateConfigStatus = createAsyncThunk(
  "config/updateConfigStatus",
  async (obj: any, { dispatch }) => {
    try {
      const response = await customAxios.put(
        `${process.env.REACT_APP_BASE_URL}stats/scrapper_config/`,
        obj

      );
      if(obj.keywords_set_to_all){
        dispatch(updateKeywordsReducer(response.data))
      }
      else
      { 
        dispatch(updateConfigStatusReducer(response.data))
      }
      // const dispatch = useDispatch<AppDispatch>() as any;
     
      // dispatch(getScrappersConfig({page: 1}))
      // return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateCommonKeywords = createAsyncThunk(
  "config/updateCommonConfigStatus",
  async (obj: any, { dispatch,  rejectWithValue}) => {
    try {
      const response = await customAxios.put(
        `${process.env.REACT_APP_BASE_URL}stats/scrapper_common_info/${obj.id}/`,
        obj

      );
        dispatch(updateCommonKeywordsReducer(response.data))
    } catch (err: any) {
      console.log(err);
      return rejectWithValue(err.response ? err.response.data : "An error occurred");

    }
  }
);





const initialState = {
  config: {},
  commonKeywords: {
    id: null,
    search_keyword: [],
    job_title: []
  }

};

const configSlice = createSlice({
  name: "config",
  initialState: initialState,
  reducers: {
    config: (state, { payload }) => {
      state.config = payload;
    },
    updateConfigStatusReducer: (state, action) => {
      // console.log(action)
      const existingRecords = JSON.parse(JSON.stringify(state.config));
      // state.push(action.payload[0])
      
      existingRecords.results.map((record: any) => {
        if (record.id === action.payload.id) {
          record.status = action.payload.status
          record.name = action.payload.name
          return state.config = existingRecords
        }
      })

    },
    updateCommonKeywordsReducer: (state, action) => {
      state.commonKeywords = JSON.parse(JSON.stringify(action.payload));
    },
    updateKeywordsReducer: (state, action) => {
      const existingRecords = JSON.parse(JSON.stringify(state.config));
      // state.push(action.payload[0])
      const newRecords = existingRecords.results.map((record: any) => {
        // if (record.search_keyword == "null") { record.search_keyword = [] } if (record.job_title == "null") record.job_title = []
        return {...record, search_keyword: action.payload.search_keyword, job_title: action.payload.job_title}
      })
      
      state.config = {...state.config, results: newRecords}

    },
  },
  extraReducers: {
    [getScrappersConfig.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [getScrappersConfig.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      config: action.payload,
      success: true,
    }),
    [getScrappersConfig.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),
    [getCommonKeywords.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [getCommonKeywords.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      commonKeywords: action.payload,
      success: true,
    }),
    [getCommonKeywords.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),
    [updateCommonKeywords.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [updateCommonKeywords.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      commonKeywords: JSON.parse(JSON.stringify(action.payload)),
      success: true,
    }),
    [updateCommonKeywords.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),
  },
});


export const { updateConfigStatusReducer, updateKeywordsReducer, updateCommonKeywordsReducer} = configSlice.actions
export default configSlice.reducer;
