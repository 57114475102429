import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAxios } from "../../Services/auth-header";


export const getPreferences = createAsyncThunk(
    "preferences/getPreferences",
    async (user, thunkAPI) => {
      try {
        const response = await customAxios.get(
          `${process.env.REACT_APP_BASE_URL}stats/prefrences/`
        );
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );
  export const updatePreferences = createAsyncThunk(
  "preferences/updatePreferences",
  async (obj:any, {dispatch}) => {
    try {
      const response = await customAxios.put(
        `${process.env.REACT_APP_BASE_URL}stats/prefrences/`,obj
      );
    //   dispatch(updatePreferencesReducer(response.data))
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const updateConfigStatus = createAsyncThunk(
    "config/updateConfigStatus",
    async (obj:any, thunkAPI) => {
      try {
        const response = await customAxios.put(
          `${process.env.REACT_APP_BASE_URL}stats/scrapper_config/`,
            obj
         
        );
        return response.data;
      } catch (err) {
        console.log(err);
      }
    }
  );





const initialState = {
  preferences: {},
  seccess:false,
  err:false
};

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
   
    updateConfigStatus:(state,action)=>{
        console.log("reducerrrr",state,action)
    },
    updatePreferencesReducer:(state,action)=>{



        


        // let existingData = JSON.parse(JSON.stringify(state.preferences));
       
        // existingData=action.payload.Data
        return state.preferences=action.payload.Data
        // existingData.results.map((record:any)=>{
        //     console.log("inmapppp")
        //     if(record.id==action.payload[0].id){
        //         console.log("fldjaslfd",record)
        //         record.status=action.payload[0].status
        //         console.log("afterupdateeee",existingRecords)
        //         return state.config=existingRecords
        //     }
        // })

    }
  },
  extraReducers: {



    [getPreferences.pending.type]: (state: any, action: any) => ({
        ...state,
      }),
      [getPreferences.fulfilled.type]: (state: any, action: any) => ({
        ...state,
        preferences: action.payload,
        success: true,
      }),
      [getPreferences.rejected.type]: (state: any, action: any) => ({
        ...state,
        err: action.payload.error,
        success: false,
      }),




  },
});







export const { updatePreferencesReducer } =preferencesSlice.actions

export default preferencesSlice.reducer;
