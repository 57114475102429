import React, { useEffect } from "react";
import { useNavigate } from "react-router";

const Protected = (props: any) => {
  const { Component } = props;

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("login", { replace: true });
    }
  }, []);
  return (
    <div>
      <Component></Component>
    </div>
  );
};

export default Protected;
