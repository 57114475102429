import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { customAxios } from "../../Services/auth-header";

export const addEmail = createAsyncThunk(
    "emailScanner/addEmail",
    async (sting: string, thunkAPI) => {
      try {
        let userObj = localStorage.getItem("userObj") as any;
        userObj = JSON.parse(userObj);
        const response = await customAxios.post(
          `${process.env.REACT_APP_BASE_URL}configure_account/?created_by=${userObj.email}&state=${sting}`
        );
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
  );
export const getEmailScanner = createAsyncThunk(
    "emailScanner/getEmailScanner",
    async (payload: any, thunkAPI) => {
      try {
        let userObj = localStorage.getItem("userObj") as any;
        userObj = JSON.parse(userObj);
        const response = await customAxios.get(
            `${process.env.REACT_APP_BASE_URL}gmail_scanner_user/?user=${userObj.email}`
        );
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
);
export const scheduleScanner = createAsyncThunk(
    "emailScanner/scheduleScanner",
    async (payload: any, thunkAPI) => {
      try {
        const response = await customAxios.get(
            `${process.env.REACT_APP_BASE_URL}gmail_scanner_scheduler/?mail=${payload.mail}&after=${payload.after}&before=${payload.before}&date=${payload.date}&time=${payload.time}`
        );
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
);

export const deleteScanner = createAsyncThunk(
    "emailScanner/deleteScanner",
    async (payload: any, thunkAPI) => {
      try {
        const response = await customAxios.delete(
            `${process.env.REACT_APP_BASE_URL}gmail_scanner_scheduler/?mail=${payload}`
        );
        return response.data;
      } catch (err) {
        return thunkAPI.rejectWithValue(err);
      }
    }
);
const initialState = {
  success: false,
  errorMSG: [],
  error: false,
  emails: [],
  response: [],
  deleteResponse: {},
};

const emailScanner = createSlice({
  name: "emailScanner",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [addEmail.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [addEmail.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      success: true,
    }),
    [addEmail.rejected.type]: (state: any, action: any) => ({
      ...state,
      errorMSG: action.payload,
      error: true,
    }),


    [getEmailScanner.pending.type]: (state: any, action: any) => ({
      //
    }),
    [getEmailScanner.fulfilled.type]: (state: any, action: any) => ({
      emails: action.payload,
      // success: true,
    }),
    [getEmailScanner.rejected.type]: (state: any, action: any) => ({
      //
    }),


    [scheduleScanner.pending.type]: (state: any, action: any) => ({
        //
    }),
    [scheduleScanner.fulfilled.type]: (state: any, action: any) => ({
      response: action.payload,
    }),
    [scheduleScanner.rejected.type]: (state: any, action: any) => ({
        //
    }),


      [deleteScanner.pending.type]: (state: any, action: any) => ({
          //
      }),
      [deleteScanner.fulfilled.type]: (state: any, action: any) => ({
          deleteResponse: {result:action.payload},
      }),
      [deleteScanner.rejected.type]: (state: any, action: any) => ({
          deleteResponse: {message: action.payload, error: true},
      }),
  },
});
export const { reset } = emailScanner.actions

export default emailScanner.reducer;
