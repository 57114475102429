// Copyright 2019-2022 @polkadot/extension-koni-ui authors & contributors
// SPDX-License-Identifier: Apache-2.0

import { createSlice } from "@reduxjs/toolkit";

export type sideBarItems = {
  dashBoard: boolean;
  ticket: boolean;
  idea: boolean;
  agents: boolean;
  contacts: boolean;
  articles: boolean;
  setting: boolean;
  subscription: boolean;
  configurations:boolean;
  preferences:boolean;
  insights:  boolean;
};

const initialState = {
  dashBoard: true,
  ticket: false,
  idea: false,
  contacts: false,
  articles: false,
  agents: false,
  setting: false,
  subscription: false,
  configurations:false,
  preferences:false,
  insights:false,
} as sideBarItems;

const sideBarSlice = createSlice({
  initialState,
  name: "sideBar",
  reducers: {
    selectSideBarItem(state, action: any) {
      const payload = action.payload;
      state.agents = true;

      switch (payload.value) {
        case "dashBoard":
          state.dashBoard = true;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;

          break;
        case "ticket":
          state.dashBoard = false;
          state.ticket = true;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;
          break;
          case "configurations":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = true;
          state.preferences = false;
          state.insights = false;
          break;
        case "idea":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = true;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;
          break;
        case "contacts":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = true;
          state.preferences = false;
          state.insights = false;
          break;
        case "articles":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = true;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;
          break;
        case "setting":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = true;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;
          break;
        case "subscription":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = true;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false
          state.preferences = false;
          state.insights = false;
          break;
        case "agents":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = true;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = false;
          break;
          case "preferences":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = true;
          state.insights = false;
          break;
          case "insights":
          state.dashBoard = false;
          state.ticket = false;
          state.idea = false;
          state.agents = false;
          state.subscription = false;
          state.setting = false;
          state.articles = false;
          state.contacts = false;
          state.configurations = false;
          state.preferences = false;
          state.insights = true;

          break;
        default:
          break;
      }
    },
  },
});

export const { selectSideBarItem } = sideBarSlice.actions;
export default sideBarSlice.reducer;
