import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState, useMemo, useCallback } from "react";

import EmailsTab from "./emailTab";
import LeadsTab from "./leadsTab";
import HealthTab from "./healthTab";

import MenuItem from "@mui/material/MenuItem";
import {
  getNamesList,
  getScrapperByName,
  leadsStatsGraph,
  setShowAccounts,
} from "../../../stores/slices/leads-slice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../stores";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ClickAwayListener from "@mui/material/ClickAwayListener";

import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";

import IconButton from "@mui/material/IconButton";
import { StyledInput, StyledDropDownIcon } from "./Tabs.style";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDown from "../../../assets/icon/down.png";
import ScrapperLeadsTab from "./scrapperLeadsTab";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      id={`simple-tabpanel-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DashBoardTabs() {
  const [value, setValue] = React.useState(0);

  const dispatch = useDispatch<AppDispatch>() as any;
  const { namesList } = useSelector((state: any) => state.leadsStats);
  const currentLeadsState = useSelector((state: any) => state.leadsStats);
  const { currentDB } = useSelector((state: any) => state.leadsStats);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<any>(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = useState([]);
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const [from, setFrom] = useState<Date | null>(date);
  const [to, setTo] = useState<Date | null>(new Date());

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue == 1) dispatch(setShowAccounts(false));
    else dispatch(setShowAccounts(true));
  };

  useEffect(() => {
    dispatch(setShowAccounts(true));
    dispatch(getNamesList());
    return () => dispatch(setShowAccounts(false));
  }, []);

  ///
  const handleToggle = (event: any) => {
    // event.stopeImmediatePropation();
    setOpen((prevOpen) => !prevOpen);
  };

  const getNamedLeads = (name: string) => {
    // setSelectedScrapper(name);
    if (name === "All") {
      const min_date = new Date();
      min_date.setMonth(min_date.getMonth() - 1);
      const max_date = new Date().toLocaleDateString();
      let obj = {
        from: new Date(`${from}`).toISOString().slice(0, 10),
        to: new Date(`${to}`).toISOString().slice(0, 10),
        db_name: currentDB,
      };
      setSearchTerm("");
      dispatch(leadsStatsGraph(obj));
    } else {
      let obj = {
        from: new Date(`${from}`).toISOString().slice(0, 10),
        to: new Date(`${to}`).toISOString().slice(0, 10),
        db_name: currentDB,
        scrapper_name: name,
      };
      dispatch(leadsStatsGraph(obj));
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
      setSearchTerm("");
    } else if (event.key === "Escape") {
      setOpen(false);
      setSearchTerm("");
    }
  }

  const handleSearch = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const searchedScrapper = useMemo(() => {
    return namesList?.names?.filter((name: string) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, namesList]);

  useEffect(() => {
    const results = namesList?.names?.filter((name: string) =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm, namesList]);

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ ml: "30px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab
            label="Leads Scrapped"
            {...a11yProps(0)}
            iconPosition="end"
            icon={
              // <IconButton
              //   ref={anchorRef}
              //   id="composition-button"
              //   aria-controls={open ? "composition-menu" : undefined}
              //   aria-expanded={open ? "true" : undefined}
              //   aria-haspopup="true"
              //   onClick={($event) => handleToggle($event)}
              // >
              //   <KeyboardArrowDownIcon />
              // </IconButton>
              <StyledDropDownIcon
                src={ArrowDown}
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={($event) => handleToggle($event)}
              ></StyledDropDownIcon>
            }
            sx={{
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
            }}
          />

          <Tab label="Scrappers Health" {...a11yProps(1)} />
          <Tab label="Emails" {...a11yProps(2)} />
          <Tab label="Leads Excel Sheet" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <LeadsTab
          scrapperName={currentLeadsState?.leadsGraphData?.scrapper_name}
          from={from}
          to={to}
          setFrom={setFrom}
          setValue={setTo}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <HealthTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmailsTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ScrapperLeadsTab />
      </TabPanel>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Box sx={{ bgcolor: "white", minWidth: "200px" }}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <StyledInput
                      onChange={($event: any) => handleSearch($event)}
                      autoFocus={true}
                    ></StyledInput>
                    <SearchIcon
                      sx={{
                        marginLeft: "-25px",
                        mt: "10px",
                        color: "lightgray",
                      }}
                    />
                  </Box>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    sx={{
                      maxHeight: "300px",
                      overflow: "scroll",
                    }}
                    variant="selectedMenu"
                    disabledItemsFocusable={true}
                    disableListWrap={true}
                  >
                    {/* <MenuItem></MenuItem> */}
                    <MenuItem
                      autoFocus
                      selected={
                        currentLeadsState?.leadsGraphData?.scrapper_name == null
                      }
                      onClick={() => getNamedLeads("All")}
                      sx={{ fontSize: "14px" }}
                    >
                      All
                    </MenuItem>
                    {searchTerm
                      ? searchedScrapper?.map((name: string) => (
                          <MenuItem
                            onClick={() => getNamedLeads(name)}
                            selected={
                              name ===
                              currentLeadsState?.leadsGraphData?.scrapper_name
                            }
                            sx={{
                              borderTop: "1px solid #DFE0EB",
                              py: "10px",
                              fontSize: "14px",
                            }}
                            key={name}
                          >
                            {name}
                          </MenuItem>
                        ))
                      : namesList?.names?.map((name: string) => (
                          <MenuItem
                            onClick={() => getNamedLeads(name)}
                            selected={
                              name ===
                              currentLeadsState?.leadsGraphData?.scrapper_name
                            }
                            sx={{
                              borderTop: "1px solid #DFE0EB",
                              py: "10px",
                              fontSize: "14px",
                            }}
                            key={name}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    {/* {searchedScrapper?.map((name: string) => (
                      <MenuItem
                        onClick={() => getNamedLeads(name)}
                        selected={
                          name ===
                          currentLeadsState?.leadsGraphData?.scrapper_name
                        }
                        sx={{
                          borderTop: "1px solid #DFE0EB",
                          py: "10px",
                          fontSize: "14px",
                        }}
                        key={name}
                      >
                        {name}
                      </MenuItem>
                    ))} */}
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default DashBoardTabs;
