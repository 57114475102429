import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import {
  StyledInput,
  StyledButton,
  StyledMainWrapper,
  StyledConfigWrapper,
  StyledPasswordWrapper,
  StyledTxtFieldWrapper,
  StyledLabel,
  StyledPswdInnerWrapper,
  StyledVisibilityIcon,
  StyledVisibilityOffIcon,
  StyledDebounceKyesWrapper,
  StyledBtnWrapper,
  StyledContainer,
  StyledInnerBtnWrapper,
} from "./Preferences.style";
import { useDebounce } from "usehooks-ts";
import { useSelector, useDispatch } from "react-redux";

import {
  getPreferences,
  updatePreferences,
} from "../../stores/slices/preferences-slice";
import { AppDispatch } from "../../stores";

import { SelectChangeEvent } from "@mui/material/Select";
import DebounceKeys from "../../components/DebounceKeys";
import { StyledTitleWrapper, StyledTitleText } from "./Preferences.style";

const Preferences = () => {
  const [value, setValue] = React.useState<number>(10);
  const debouncedValue = useDebounce<string>(`${value}`, 500);
  const { preferences } = useSelector((state: any) => state.preferences);
  const dispatch = useDispatch<AppDispatch>() as any;
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [preference, setPreference] = React.useState({
    id: preferences?.id,
    delay: preferences?.delay,
    pages_to_visit: preferences?.pages_to_visit,
    email: preferences?.email,
    password: preferences?.password,
    debounce_keys: preferences?.debounce_keys
      ? preferences.debounce_keys
      : "[]",
  });

  const [passwordVisibility, setPasswordVisibility] = React.useState(false);

  const handleInputChange = (e: any) => {
    setPreference({
      ...preference,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    dispatch(getPreferences());
  }, []);

  const handleSelectChange = (event: SelectChangeEvent<typeof personName>) => {
    setPreference({
      ...preference,
      pages_to_visit: event.target.value,
    });

    dispatch(updatePreferences(preference));
  };

  const handleSave = () => {
    dispatch(updatePreferences(preference));
  };
  useEffect(() => {
    setPreference({
      id: preferences?.id,
      delay: preferences?.delay,
      pages_to_visit: preferences?.pages_to_visit,
      email: preferences?.email,
      password: preferences?.password,
      debounce_keys: preferences?.debounce_keys
        ? preferences.debounce_keys
        : "[]",
    });
  }, [preferences]);
  React.useEffect(() => {
    if (preference.id) {
      dispatch(updatePreferences(preference));
    }
  }, [debouncedValue]);

  return (
    <StyledMainWrapper>
      <StyledTitleWrapper>
        <StyledTitleText>Send Emails Through</StyledTitleText>
      </StyledTitleWrapper>
      <Box>
        <StyledConfigWrapper>
          <StyledTxtFieldWrapper>
            <StyledLabel>Email</StyledLabel>
            <StyledInput
              value={preference?.email}
              name="email"
              onChange={(e: any) => handleInputChange(e)}
            ></StyledInput>
          </StyledTxtFieldWrapper>
          <StyledPasswordWrapper>
            <StyledLabel>Password</StyledLabel>
            <StyledPswdInnerWrapper>
              <StyledInput
                name="password"
                type={passwordVisibility ? "text" : "password"}
                value={preference?.password}
                onChange={(e: any) => handleInputChange(e)}
              ></StyledInput>
              {passwordVisibility ? (
                <StyledVisibilityOffIcon
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                />
              ) : (
                <StyledVisibilityIcon
                  onClick={() => setPasswordVisibility(!passwordVisibility)}
                />
              )}
            </StyledPswdInnerWrapper>
          </StyledPasswordWrapper>
        </StyledConfigWrapper>
        <StyledBtnWrapper>
          <StyledContainer></StyledContainer>
          <StyledInnerBtnWrapper>
            <StyledButton onClick={handleSave}>Save</StyledButton>
          </StyledInnerBtnWrapper>
        </StyledBtnWrapper>
      </Box>
      <StyledTitleWrapper>
        <StyledTitleText>Debounce Keys</StyledTitleText>
      </StyledTitleWrapper>
      <StyledDebounceKyesWrapper>
        <DebounceKeys preferences={preferences} />
      </StyledDebounceKyesWrapper>
    </StyledMainWrapper>
  );
};

export default Preferences;
