import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  StyledCloseIcon,
  StyledHeadingWrapper,
  StyledDeleteMessage,
  StyledDeleteBtn,
  StyledDeleteCancelBtn,
  StyledDeleteDialogWrapper,
  StyledDeleteBtnWrapper,
  StyledDeleteHeading,
} from "./EmailScanner.style";
import CloseIcon from "../../assets/icon/closeicon.png";
import {deleteScanner, getEmailScanner} from "../../stores/slices/email-scanner-slice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../stores";
import { successToast } from "../../Services/toast";

export default function ScheduleDialog(props: any) {

  const dispatch = useDispatch<AppDispatch>() as any;
  const { deleteResponse } = useSelector((state: any) => state.emailScanner);

  const handleDelete = () => {
    dispatch(deleteScanner(props?.email));
    props?.handleClose()
  }

  React.useEffect(() => {
    if (deleteResponse?.result?.success === 'True'){
      props?.handleClose()
      successToast(deleteResponse?.result?.data)
      dispatch(getEmailScanner(1));
    }
  }, [deleteResponse]);
  return (
    <div>
      <Dialog
        open={props?.isOpen}
        onClose={props?.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <StyledHeadingWrapper>
            <StyledDeleteHeading>Sender account deletion</StyledDeleteHeading>
            <StyledCloseIcon
              src={CloseIcon}
              onClick={props?.handleClose}
            ></StyledCloseIcon>
          </StyledHeadingWrapper>
        </DialogTitle>
        <DialogContent>
          <StyledDeleteDialogWrapper>
            <StyledDeleteMessage>
              Are you sure you want to delete this sender account?
            </StyledDeleteMessage>
          </StyledDeleteDialogWrapper>
        </DialogContent>
        <DialogActions>
          <StyledDeleteBtnWrapper>
            <StyledDeleteCancelBtn onClick={props.handleClose}>
              Cancel
            </StyledDeleteCancelBtn>
            <StyledDeleteBtn onClick={handleDelete}>
              Yes, Delete
            </StyledDeleteBtn>
          </StyledDeleteBtnWrapper>

        </DialogActions>
      </Dialog>
    </div>
  );
}
