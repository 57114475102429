import styled from "styled-components";
import Card from "@mui/material/Card";
import Button from '@mui/material/Button';

export const StyledLoginWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: "100%";
  color: black;
  // background-color: rgb(229, 229, 229);
`;

export const StyledInput = styled.input`
  width: 100%;

  padding: 0.6em 0.8em;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.04);
  width: 100%;
  border: 1px solid transparent;
  outline: none;
  margin: 5px auto;
  font-weight: 500;
  font-size: 17px;

  transition: 0.2s ease-in-out &focus {
    outline: none;
    border: 1px solid red;
  }
`;

export const StyledButton = styled.button`
  height: 100%;
  max-height: 45px;
  padding: 8px 16px;
  background: #043E74;
  border-radius: 2px;
  border-color: #043E74;
  &:hover {
    background: #043E74;
    border-color: #043E74;
    opacity: 0.8;
  }

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  width: 100%;
  border-radius: 20px;
  margin-top:8px;
  color: #ffff;
  border: 1px solid #043E74; 
  margin-left:24px
  
`;
export const StyledHeading = styled.h2`
display:flex;
justify-content:center;
 color:#043E74;
`;
export const StyledCard = styled(Card)`

box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.05) !important;
border-radius: 5px;
min-width: 400px;

`;

export const StyledGoogleLoginWrapper = styled.div`
display:flex;
justify-content:center;

`;



export const StyledLoaderWrapper = styled.div`
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
width: "100%";
color: black;
// background-color: rgb(229, 229, 229);
margin:auto;

`;
export const StyledLoginText = styled.p`
// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: center;
// width: "100%";
color: black;
font-size:40px;
font-weight:bold;
margin-bottom:0px;


`;
export const StyledTextWrapper = styled.div`

display: flex;
flex-direction:column;
justify-content: center;
align-items:center;

color: black;


`;

export const StyledWelcomeMessage = styled.p`
// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: center;
// width: "100%";
// color: gray;
// font-weight:bold;
// margin:auto;


font-weight: bolder;
font-size: 24px;



color: #031838;
margin-top:-32px;
margin-bottom:8px;


`;





export const StyledSigninMessage = styled.p`

font-weight: 500;
font-size: 14.765px;
line-height: 17px;
text-align: center;

color: #838383;
margin-top:0px


`;











export const StyledLogoWrapper = styled.div`
display:flex;

`;
export const StyledLogo = styled.img`
height:220px;
width:220px;
margin-right:-20px !important;

`;
export const StyledGoodleBtnWrapper = styled.div`
display:flex;
justify-content:center;
margin-top:4px;

`;

export const StyledBtnText = styled.p`
// margin:  0px;
// margin-top:4px;
// margin-left:8px;
// color:gray;
// font-weight:bold;


font-weight: 500;
font-size: 12px;


color: rgba(48, 48, 48, 0.9);
margin-left:8px;




`;

export const StyledGoogleBtn = styled(Button)`
background-color: white;
border:none;
padding:8px;
display:flex;
border-radius:4px;


background: #FFFFFF;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 15px !important;
padding-left:32px !important;
padding-right:32px !important;
text-transform:capitalize !important;






`;



export const StyledGoogleIcon = styled.img`
background-color:white;
margin:auto;



`;

