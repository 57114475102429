import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { prototype } from "events";
import { act } from "react-dom/test-utils";
import { customAxios } from "../../Services/auth-header";

export const scrappersStats = createAsyncThunk(
  "scrappers/scrappersStates",
  async (User, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/scrappers_stats/`
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

interface scrapperObj {
  order: string;
  page: number;
  to: string | Date | null;
  from: string | Date | null;
  perPage: string | number | null;
  searchValue: string | null | undefined;
  filter: string | null | undefined;
}
interface leadsCSVObj {
  scrapper_name: string | null;
  leads_date: string | Date | null;
}
export const getScrappersHealth = createAsyncThunk(
  "scrappers/getScrappersHealth",
  async (obj: scrapperObj, thunkAPI) => {
    const searchedValue = obj.searchValue ? obj.searchValue : "";
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/scrappers_stats/?ordering=${
          obj.order
        }&page=${obj.page}&min_date=${obj.from}&max_date=${obj.to}&per_page=${
          obj.perPage
        }&search=${searchedValue}&filter_on_scrapper=${
          obj?.filter == "all" ? "none" : obj.filter
        }`
      );
      // console.log(response)
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getLeadsCSV = createAsyncThunk(
  "scrappers/getLeadsCSV",
  async (obj: leadsCSVObj, thunkAPI) => {
    try {
      const response = new Promise<any>((resolve, reject) => {
        customAxios
          .get(
            `${process.env.REACT_APP_BASE_URL}stats/scrappers_stats_leads/?date=${obj.leads_date}&scrapper_name=${obj.scrapper_name}`
          )
          .then((res) => {
            resolve(res);
          });
      });

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const searchScrappers = createAsyncThunk(
  "scrappers/getScrappersHealth",
  async (obj: any, thunkAPI) => {
    const searchedValue = obj.searchValue ? obj.searchValue : "";

    try {
      const response = await customAxios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }stats/scrappers_stats/?search=${searchedValue}&ordering=${
          obj.order
        }&page=${obj.page}&min_date=${obj.from}&max_date=${obj.to}&per_page=${
          obj.perPage
        }&filter_on_scrapper=${obj?.filter == "all" ? "none" : obj.filter}`
      );
      // console.log(response);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getScrapperLogs = createAsyncThunk(
  "scrappers/getScrapperLogs",
  async (page: number, thunkAPI) => {
    try {
      const response = await customAxios.get(
        `${process.env.REACT_APP_BASE_URL}stats/logs/?page=${page}`
      );
      // console.log(response)
      return response.data;
    } catch (err) {
      console.log(err);
    }
  }
);

export const runGmailScrapper = createAsyncThunk(
  "scrappers/runGmailScrapper",
  async (obj: any, thunkAPI) => {
    try {
      const response = await customAxios.post(
        `${process.env.REACT_APP_BASE_URL}run_sh/`,
        obj
      );
      return response.data;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

const initialState = {
  scrappers_stats: {},
  loginResponse: false,
  token: "",
  updateUserResponse: false,
  err: "",
  success: false,
  scrappersHealth: {},
  scrapperLogs: [],
  next_page: 0,
  prev_page: 0,
  scrapperHealthLoading: false,
  gmailScrapperResponse: "",
  gmailScrapperSuccess: false,
  gmailScrapperErrorMsg: "",
  gmailScrapperError: false,
};

const scrappersStatsSlice: any = createSlice({
  name: "scrappers",
  initialState,
  reducers: {
    loads_stats: (state, { payload }) => {
      state.scrappers_stats = payload;
    },
    resetAlerts(state, action: any) {
      state.gmailScrapperError = false;
      state.gmailScrapperErrorMsg = "";
      state.gmailScrapperSuccess = false;
      state.gmailScrapperResponse = "";
    },
  },
  extraReducers: {
    [scrappersStats.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [scrappersStats.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      scrappers_stats: action.payload,
      success: true,
    }),
    [scrappersStats.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [getScrappersHealth.pending.type]: (state: any, action: any) => ({
      ...state,
      scrapperHealthLoading: true,
    }),
    [getScrappersHealth.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      scrappersHealth: action.payload,
      scrapperHealthLoading: false,

      success: true,
    }),
    [getScrappersHealth.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [getScrapperLogs.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [getScrapperLogs.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      scrapperLogs: [...state.scrapperLogs, ...action.payload.data],
      next_page: action.payload.next_page,
      prev_page: action.payload.previous_page,
      success: true,
    }),
    [getScrapperLogs.rejected.type]: (state: any, action: any) => ({
      ...state,
      err: action.payload.error,
      success: false,
    }),

    [runGmailScrapper.pending.type]: (state: any, action: any) => ({
      ...state,
    }),
    [runGmailScrapper.fulfilled.type]: (state: any, action: any) => ({
      ...state,
      gmailScrapperResponse: action.payload,
      gmailScrapperSuccess: true,
      gmailScrapperError: false,
    }),
    [runGmailScrapper.rejected.type]: (state: any, action: any) => ({
      ...state,
      gmailScrapperErrorMsg: action.payload?.response?.data?.email[0],
      gmailScrapperError: true,
      gmailScrapperSuccess: false,
    }),
  },
});

export const { resetAlerts } = scrappersStatsSlice.actions;
export default scrappersStatsSlice.reducer;
