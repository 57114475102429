import * as React from "react";
import {
  StyledFormWrapper,
  StyledPara,
  StyledSecondWrapper,
  StyledHeadingText,
} from "./EmailWarmup.style";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  Button,
  CardActionArea,
  Chip,
  Grid,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import CustomizedGraph from "../../assets/icon/customizedGraph.svg";
import ProgressiveGraph from "../../assets/icon/progressiveGraph.svg";
import SteadyGraph from "../../assets/icon/steadyGraph.svg";
import "./EmailWarmup.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
export default function WarmUpSettings(props: any) {
  const [activeProgGraph, setActiveProgGraph] = React.useState(true);
  const [activeSteadyGraph, setSteadyGraph] = React.useState(false);
  const input = document.getElementById("message") as HTMLInputElement | null;
  const setActiveGraph = () => {
    if (activeProgGraph == true) {
      setActiveProgGraph(false);
      setSteadyGraph(true);
    }
    if (activeSteadyGraph == true) {
      setSteadyGraph(false);
      setActiveProgGraph(true);
    }
  };
  const [counterVol, setCounter] = React.useState(1);
  const incrementCounter = () => setCounter(counterVol + 1);
  let decrementCounter = () => setCounter(counterVol - 1);

  const [counterDaily, setDailyCounter] = React.useState(7);
  const incrementDailyCounter = () => setDailyCounter(counterDaily + 1);
  let decrementDailyCounter = () => setDailyCounter(counterDaily - 1);

  const [counterDailyGoal, setDailyGoalCounter] = React.useState(25);
  const incrementDailyGoalCounter = () =>
    setDailyGoalCounter(counterDailyGoal + 1);
  let decrementDailyGoalCounter = () =>
    setDailyGoalCounter(counterDailyGoal - 1);
  return (
    <div>
      <Box>
        <StyledSecondWrapper>
          <h1 className="Heading-2">Select your warm-up strategy</h1>
          <StyledFormWrapper>
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} md={3} lg={4}>
                <Card
                  sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}
                  onClick={setActiveGraph}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={ProgressiveGraph}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          item
                          lg={6}
                          xs={6}
                          md={6}
                          className="Card-Heading"
                        >
                          Progressive
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          lg={6}
                          xs={6}
                          md={6}
                        >
                          <InfoOutlinedIcon />
                        </Grid>
                      </Grid>
                      <Grid container className={"Card-Content"}>
                        This is the number of email messages we'll send on the
                        first day of this warm-up campaign.
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Card
                  sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}
                  onClick={setActiveGraph}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={SteadyGraph}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          item
                          lg={6}
                          xs={6}
                          md={6}
                          className="Card-Heading"
                        >
                          Steady
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          lg={6}
                          xs={6}
                          md={6}
                        >
                          <InfoOutlinedIcon />
                        </Grid>
                      </Grid>
                      <Grid container className={"Card-Content"}>
                        This is the number of email messages we'll send on the
                        first day of this warm-up campaign.
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Card sx={{ boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.2)" }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={CustomizedGraph}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid
                          item
                          lg={8}
                          xs={6}
                          md={6}
                          className="Card-Heading"
                        >
                          Custom (Advanced)
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="flex-end"
                          lg={4}
                          xs={6}
                          md={6}
                        >
                          <InfoOutlinedIcon />
                        </Grid>
                      </Grid>
                      <Grid container className={"Custom-Card-Content"}>
                        Build your own warm-up campaign strategy.
                      </Grid>
                      <Grid container justifyContent="flex-end">
                        <Chip label="Coming Soon" variant="outlined" />
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
            {activeProgGraph == true ? (
              <div>
                {" "}
                <Box sx={{ mb: 4 }}>
                  <StyledHeadingText>
                    Starting volume
                  </StyledHeadingText>
                  <StyledPara>
                      This is the number of email messages we'll send on the
                      first day of this warm-up campaign.
                  </StyledPara>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <PlayCircleOutlineIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={counterVol}
                      inputProps={{ "aria-label": "search google maps" }}
                      id="message"
                    />
                    <Grid container item direction={"column"} lg={1} md={1} xs={1}>
                      <IconButton
                        sx={{ p: "0px" }}
                        aria-label="directions"
                        onClick={incrementCounter}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                      <IconButton
                        sx={{ p: "0px" }}
                        aria-label="directions"
                        onClick={decrementCounter}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Paper>
                </Box>
                <Box sx={{ mb: 4 }}>
                  <StyledHeadingText>
                    Daily volume increase
                  </StyledHeadingText>
                  <StyledPara>
                      We'll keep growing the sending volume by this number of
                      messages every day.
                  </StyledPara>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={counterDaily}
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <Grid container direction={"column"}item lg={1} md={1} xs={1}>
                      <IconButton
                        sx={{ p: "0px" }}
                        aria-label="directions"
                        onClick={incrementDailyCounter}
                      >
                        <ExpandLessIcon />
                      </IconButton>
                      <IconButton
                        sx={{ p: "0px" }}
                        aria-label="directions"
                        onClick={decrementDailyCounter}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Paper>
                </Box>
                <Box sx={{ mb: 4 }}>
                  <StyledHeadingText>
                    Daily sending goal
                  </StyledHeadingText>
                  <StyledPara>
                      This is the sending goal of your Progressive warm-up
                      campaign. After reaching this goal, the warm-up campaign
                      will continue steadily sending this number of email
                      messages every day till the scheduled end. We recommend to
                      set this goal at 40. Please note that warm-up emails are
                      not counted into your sender account's Messages per day
                      limit.
                  </StyledPara>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
                    }}
                  >
                    <IconButton sx={{ p: "10px" }} aria-label="menu">
                      <EmailOutlinedIcon />
                    </IconButton>
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      value={counterDailyGoal}
                      inputProps={{ "aria-label": "search google maps" }}
                    />
                    <Grid container direction={"column"} item lg={1} md={1} xs={1}>
                      <IconButton sx={{ p: "0px" }} aria-label="directions" onClick={incrementDailyGoalCounter}>
                        <ExpandLessIcon />
                      </IconButton>
                      <IconButton sx={{ p: "0px" }} aria-label="directions" onClick={decrementDailyGoalCounter }>
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Paper>
                </Box>
                <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ border: "1px solid #043E74", color: "#043E74" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      ml: 2,
                      backgroundColor: "#043E74",
                    }}
                    onClick={props.handleNextProp}
                  >
                    Next
                  </Button>
                </Grid>
              </div>
            ) : (
              <Box>
                <StyledHeadingText>
                  <p>Messages per day</p>
                </StyledHeadingText>
                <StyledPara>
                  <p>
                    The number of emails that will be sent every day for this
                    warm-up campaign. We recommend to set this limit at 40.
                    Please note that warm-up emails are not counted into your
                    sender account's Messages per day limit.
                  </p>
                </StyledPara>
                <Paper
                  component="form"
                  sx={{
                    p: "2px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    boxShadow: "0px 1px 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <IconButton sx={{ p: "10px" }} aria-label="menu">
                    <EmailOutlinedIcon />
                  </IconButton>
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value="25"
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                  <Grid container direction={"column"} item lg={1} md={1} xs={1}>
                    <IconButton sx={{ p: "0px" }} aria-label="directions">
                      <ExpandLessIcon />
                    </IconButton>
                    <IconButton sx={{ p: "0px" }} aria-label="directions">
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                </Paper>
                <Grid container justifyContent="flex-end" sx={{ mb: 2, mt: 4 }}>
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{ border: "1px solid #043E74", color: "#043E74" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      ml: 2,
                      backgroundColor: "#043E74",
                    }}
                  >
                    Next
                  </Button>
                </Grid>
              </Box>
            )}
            {/*------ Steady Graph --------*/}
          </StyledFormWrapper>
        </StyledSecondWrapper>
      </Box>
    </div>
  );
}
